import Type1 from './type-1'

export const Types = {
    TYPE_1: 'sigin-1',
}

export default type => {
    switch(type){
        case Types.TYPE_1:
            return Type1
        default:
            return
    }
}