import React, { useState } from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { Iconfont } from '../../../../../../../components/icon/iconfont'
import Swiper from '../../../../../../../components/swiper/autowidth';


export default props => {
    useStyles(classes);
    const { 
        items, 
        slidesPerView, 
        slidesPerGroup, 
        options, 
        showBgNavigation, 
        prevStyles = {}, 
        nextStyles = {} 
    } = props
    const { container : prevContainerStyle, icon : prevIconStyle } = prevStyles;
    const { container : nextContainerStyle, icon: nextIconStyle } = nextStyles;
    const [swiper, setSwiper] = useState()

    const clickHandle = (flag) =>{
        if(!!flag){
            swiper.slidePrev(500);
        }else{
            swiper.slideNext(500);
        }
    }
    
    return <div className={`${classes.SwiperProducts} ${showBgNavigation?classes.BgNavigation:''} ${props.className}`}>
            <Swiper options={{
                slidesPerView: slidesPerView || 5,
                slidesPerGroup : slidesPerGroup ? slidesPerGroup : slidesPerView || 5,
                spaceBetween: 20,
                onSwiper:(s)=>setSwiper(s),
                navigation: {
                    prevEl: null,
                    nextEl: null,
                },
                loop: true,
                ...options
            }} items={items} render={(item, index) => props.render(item, index)} style={props.style} />

            <span className={`${classes.Prev}`} style={prevContainerStyle} onClick={()=>clickHandle(true)}>
                <Iconfont style={prevIconStyle}>&#xe693;</Iconfont>
            </span>
            <span className={`${classes.Next}`} style={nextContainerStyle} onClick={()=>clickHandle(false)}>
                <Iconfont style={nextIconStyle}>&#xe694;</Iconfont>
            </span>
    </div>
}