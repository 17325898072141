import React, {useCallback} from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import withCollections from '../../../../../pages/moduleable/modules/collections/withCollections'
import ListSwiperProducts from '../list/components/list-swiper-products/index';
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { getCollectionUrl } from '../../../../../../utils/url'
import { IMAGE_GEEKO_LTD } from '../../../../../../constants'
import { Types } from './index'
import {getStyleObjectFromString} from '../../../../../utils/style'
import withSouce from '../../../../../hocs/source-scoll'
import { getSensorsUrl } from '../../../../../utils/sensor';


const Collection = withSouce(props => {
    useStyles(classes)
    const { collection, sensors, position, innerRef } = props
    const url = collection?.relatePcHref || getCollectionUrl(collection?.id, collection?.name)

    const clickHandle = useCallback(e => {
		e.preventDefault()
		const href = e.currentTarget?.href
		window.location.href = getSensorsUrl(href, {
			resourcepage_title: sensors?.title,
			resource_type: '1',
			resource_content: sensors?.refId,
			resource_position: `${sensors?.position}-${(position || 0) + 1}`,
		})
	}, [])


    return <a
        ref={innerRef}
        data-position={`${sensors?.position || 1}-${(position || 0) + 1}`}
        data-type={sensors?.type}
        data-content={sensors?.refId}
        data-title={sensors?.title}
        href={url}
        className={classes.CollectionContainer}
        onClick={clickHandle}
        >
        <div className={classes.Image}>
            <LazyLoadImage alt={collection?.name} src={`${IMAGE_GEEKO_LTD}${collection?.pcImages?.[0]}`} />

            <div className={classes.ViewProduct}>
                <div>View Products</div>
            </div>
        </div>
        <div className={classes.Name}>
            {
                collection?.name
            }
        </div>
    </a>
})

const CollectionsContainer = withCollections(props =>{
    useStyles(classes)
    const { sensors, collections } = props;

    return <div className={`${classes.NavigationContainer} ${classes.NavigationCollection3Bd}`}>
        <ListSwiperProducts 
            items={collections}
            slidesPerView={4}
            render={(item, index) => {
                return <Collection collection={item} position={index} sensors={{
                    ...sensors,
                    refId: item.enName || item.name,
                    type: '1',
                }} />
            }} 
        />
    </div>
})

export default (props => {
    useStyles(classes)
    const { data, sensors } = props
    const { styledTitle } = data
    const styles = getStyleObjectFromString(data?.style)

    return <div className={`${classes.Container3} ${!data?.full? 'MaxWith': ''}`} id={data?.id} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>
        {
            styledTitle && <div className={classes.Title}>
                <a href={'/i/collections'} dangerouslySetInnerHTML={{ __html: styledTitle }} />
            </div>
        }

        
        <CollectionsContainer sensors={sensors} collectionCount={12} />
    </div>
})