// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1opwB4yCqMUbDaQeQsgC-F{height:60px;display:flex;background-color:#eff2f6;padding:10px 0}._1opwB4yCqMUbDaQeQsgC-F ._37_840KCh7q6JxxDCYnPsM{flex:1;border-left:1px solid #222}._1opwB4yCqMUbDaQeQsgC-F ._37_840KCh7q6JxxDCYnPsM:first-child{border-left:none}._1opwB4yCqMUbDaQeQsgC-F ._1UULbKg_dLfeCg_PkOks5_{display:flex;justify-content:center;align-items:center;text-decoration:none;color:#222}._1opwB4yCqMUbDaQeQsgC-F ._1UULbKg_dLfeCg_PkOks5_ ._10uQ2uOV-r073ulFaz9YTf{padding-right:20px}._1opwB4yCqMUbDaQeQsgC-F ._1UULbKg_dLfeCg_PkOks5_ ._-JrWkQghtaekxOZ8wmU3{line-height:40px;height:40px;display:inline-block}.cR67zgoLShArl4RaqKV9y{height:100%}\n", "",{"version":3,"sources":["webpack://src/client/desktop/pages/moduleable/modules/tips/style.module.scss"],"names":[],"mappings":"AAAA,yBACI,WAAW,CACX,YAAY,CACZ,wBAAyB,CACzB,cAAe,CAJnB,kDAOQ,MAAM,CACN,0BAA2B,CARnC,8DAWY,gBAAgB,CAX5B,kDAgBQ,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CACnB,oBAAqB,CACrB,UAAW,CApBnB,2EAuBY,kBAAkB,CAvB9B,yEA2BY,gBAAiB,CACjB,WAAY,CACZ,oBAAoB,CACvB,uBAKL,WAAW","sourcesContent":[".TipsContainer{\n    height:60px;\n    display:flex;\n    background-color: #eff2f6;\n    padding: 10px 0;\n\n    .TipsCon{\n        flex:1;\n        border-left: 1px solid #222;\n\n        &:first-child{\n            border-left:none;\n        }\n    }\n\n    .TipsItem{\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        text-decoration: none;\n        color: #222;\n\n        .IconContainer{\n            padding-right:20px;\n        }\n\n        .Message{\n            line-height: 40px;\n            height: 40px;\n            display:inline-block;\n        }\n    }\n}\n\n.TipSwiper{\n    height:100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TipsContainer": "_1opwB4yCqMUbDaQeQsgC-F",
	"TipsCon": "_37_840KCh7q6JxxDCYnPsM",
	"TipsItem": "_1UULbKg_dLfeCg_PkOks5_",
	"IconContainer": "_10uQ2uOV-r073ulFaz9YTf",
	"Message": "_-JrWkQghtaekxOZ8wmU3",
	"TipSwiper": "cR67zgoLShArl4RaqKV9y"
};
module.exports = ___CSS_LOADER_EXPORT___;
