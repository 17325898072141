import Type1 from './type-1'

export const Types = {
    TYPES_1: 'vendors',
}

export default type => {
    switch(type){
        case Types.TYPES_1:
            return Type1
        default:
            return
    }
}