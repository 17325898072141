import React, {useCallback} from 'react'
import ImageContainer from '../../../../../components/image/image-container'
import withLastMoreList from '../../../../../pages/moduleable/modules/list/hoc/withLastMoreList'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ListSwiperProducts from './components/list-swiper-products'
import SimpleProduct from '../../../listing/components/item/landing-item';
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import {getStyleObjectFromString} from '../../../../../utils/style'
import withObserver from '../../../../../pages/moduleable/modules/hoc/withObserver'
import { getSensorsUrl } from '../../../../../utils/sensor';
import { FormattedMessage } from 'react-intl'
import { IMAGE_GEEKO_LTD } from '../../../../../../constants'


export default withLastMoreList(withObserver(props => {
    useStyles(classes)
    const { products, data, sensors, innerRef } = props

    const styles = getStyleObjectFromString(data?.style)
    const productTitleStyle = getStyleObjectFromString(data?.productTitleStyle)
    const productDesStyle = getStyleObjectFromString(data?.productDesStyle)

    const backgroundImage = data?.backgroundImage ? 
                            data?.backgroundImage.indexOf('http') != -1 ? 
                            'url('+data?.backgroundImage+')' : 
                            'url('+IMAGE_GEEKO_LTD + data?.backgroundImage+')': 
                            ''

    const listStyles = getStyleObjectFromString(data?.listStyle);

    const url = data?.href

    const clickHandle = useCallback(e => {
		e.preventDefault()
		const href = e.currentTarget?.href
		window.location.href = getSensorsUrl(href, {
			resourcepage_title: sensors?.title,
			resource_type: '1',
			resource_content: data?.refId,
			resource_position: sensors?.position,
		})
	}, [])


    return <div ref={innerRef} 
                id={data?.id} 
                className={`${classes.List8Container} ${!data?.full? 'MaxWith': ''}`}  
                style={{ 
                    paddingTop: `${data?.marginTop}`, 
                    backgroundImage: `${backgroundImage}`, 
                    backgroundSize: '100% 100%',
                    ...styles 
                }}>
        {/* {
            data?.styledTitle && <div className={classes.Title}>
                <a onClick={clickHandle} href={url} dangerouslySetInnerHTML={{ __html: data.styledTitle }} />
            </div>
        } */}

        <div className={`${classes.List8Products}`} style={{...listStyles}}>
            {
                data?.typeRight &&
                <div className={classes.List8ImgBox}>
                    {/* href={url} */}
                    {
                        data?.src && <ImageContainer className={classes.Image} sensors={{
                            ...sensors,
                            refId: data?.refId,
                            type: '1',
                        }}>
                            <LazyLoadImage alt={data?.title} src={data?.src} />
                        </ImageContainer>
                    }
                </div>
            }
           
            
            <div className={classes.List8Products} style={{alignItems: `${data?.textAlign == 'right'? 'flex-end':'flex-start'}`}}>
                <div className={classes.List8ProductsTitle} style={{textAlign: `${data?.textAlign == 'right'?'right': 'left'}`,...productTitleStyle}}>{data?.productTitle}</div>
                <div className={classes.List8ProductsDes} style={{textAlign: `${data?.textAlign == 'right'?'right': 'left'}`,...productDesStyle}}>{data?.productDes}</div>

                <div className={classes.List8ProductsBox}>
                    <ListSwiperProducts 
                        items={products} 
                        slidesPerView={3}
                        options={{
                            loop:false
                        }}
                        showBgNavigation
                        className={classes.ListBd}
                        render={(item, index)=>{
                            return <React.Fragment>
                                <SimpleProduct 
                                    sensors={sensors} 
                                    column={data?.refId} 
                                    product={item} 
                                    position={index}
                                    />
                            </React.Fragment>
                            
                        }}
                    />
                    <div className={classes.List8ViewMore} 
                         onClick={() => {
                            window.location.href = getSensorsUrl(url, {
                                resourcepage_title: sensors?.title,
                                resource_type: '1',
                                resource_content: data?.refId,
                                resource_position: sensors?.position,
                            })
                         }}>
                            <FormattedMessage id="view_all" defaultMessage="View All" />{' >'}
                    </div>
                </div>
                
            </div>

            {
                !data?.typeRight &&
                <div className={classes.List8ImgBox}>
                    {
                        data?.src && <ImageContainer className={classes.Image} href={url} sensors={{
                            ...sensors,
                            refId: data?.refId,
                            type: '1',
                        }}>
                            <LazyLoadImage alt={data?.title} src={data?.src} />
                        </ImageContainer>
                    }
                </div>
            }
            
        </div>
    </div>
}))