import TypeAnniversary from './type-anniversary'

export const Types = {
    TYPES_ANNIVERSARY: 'lottery-anniversary',
}

export default type => {
    switch (type) {
        case Types.TYPES_ANNIVERSARY:
            return TypeAnniversary    
        default:
            return
    }
}