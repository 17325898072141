import React, {useCallback} from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import withSource from '../../hocs/source-scoll'
import { getSensorsUrl } from '../../utils/sensor'

export default withSource(props => {
    useStyles(classes)
    const { href, title, sensors, innerRef, position, className } = props

    const sensorsPosition = sensors?.position || 1

    const clickHandle = useCallback(e => {
		e.preventDefault()
		const href = e.currentTarget?.href
		window.location.href = getSensorsUrl(href, {
			resourcepage_title: sensors?.title,
			resource_type: sensors?.type,
			resource_content: sensors?.refId,
			resource_position: position !== undefined ? `${sensorsPosition}-${Number(position||0) + 1}`: sensorsPosition,
		})
	}, [])


    return href ? <a 
        href={href} 
        title={title}
        ref={innerRef}
        className={`${classes.Container} ${className}`}
        data-position={position !== undefined ? `${sensorsPosition}-${Number(position||0) + 1}`: sensorsPosition}
		data-type={sensors?.type}
		data-content={sensors?.refId}
		data-title={sensors?.title}
        style={props.style}
        onClick={clickHandle}
        >
        {props.children}
    </a>: <div
            title={title}
            ref={innerRef}
            className={`${classes.Container} ${className}`}
            style={props.style}
            data-position={`${sensors?.position || 1}-${(position||0) + 1}`}
            data-type={sensors?.type}
            data-content={sensors?.refId}
            data-title={sensors?.title}
            onClick={clickHandle}
            >
        {
            props.children
        }
    </div>
})