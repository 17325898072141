import React from 'react'
import withList from '../../../../../pages/moduleable/modules/list/hoc/withList'
import ImageContainer from '../../../../../components/image/image-container'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Product from '../../../../../desktop/pages/listing/components/item/index';
import ListSwiperProducts from './components/list-swiper-products'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import {getStyleObjectFromString} from '../../../../../utils/style'
import { Types } from './index'
import withObserver from '../../../../../pages/moduleable/modules/hoc/withObserver'
import { FormattedMessage } from 'react-intl'

export default withList(withObserver(props => {
    useStyles(classes)
    const { products, data, sensors, innerRef } = props

    const styles = getStyleObjectFromString(data?.style)

    const listStyles = getStyleObjectFromString(data?.listStyle);

    const url = data?.href

    const aspectRatio = data?.width && data?.height ? `${data?.width} / ${data?.height}` : undefined


    return <div ref={innerRef} id={data?.id} className={`${classes.ListContainer} ${!data?.full? 'MaxWith': ''}`}  style={{ paddingTop: `${data?.marginTop}`, ...styles }}>
        <div className={classes.List2Hd}>
            {
                data?.styledTitle && <div className={classes.Title}>
                    <a href={url}>
                        <span className={classes.Span1} dangerouslySetInnerHTML={{ __html: data.styledTitle }}></span>
                        <span className={classes.Span2}></span>
                    </a>
                </div>
            }

            <div className={classes.TopRightViewAll}>
                <a href={url}><FormattedMessage id="view_all" defaultMessage="View All" />{' >'}</a>
            </div>
        </div>
        
        

        {
            data?.src && <div className={classes.ListImage}>
            <ImageContainer style={{aspectRatio}} href={url} sensors={{
                ...sensors,
                refId: data?.refId,
                type: '1',
            }}>
                <LazyLoadImage alt={data?.title} src={data?.src} />
            </ImageContainer>
        </div>
        }

        <div className={`${classes.List4Products}`} style={{...listStyles}}>
            <ListSwiperProducts 
                
                items={products} 
                slidesPerView={6}
                render={(item, index)=>{
                    return <Product sensors={
                        {
                            resourcepage_title: sensors?.title,
                            resource_position: sensors?.position,
                            resource_content: data?.refId,
                            resource_type: '1',
                        }
                    } column={data?.refId} product={item} position={index}/>
                }}
            />
        </div>
    </div>
}))