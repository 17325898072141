import React, {createRef, useEffect, useState} from 'react'

export default Wrapped => {

    return props => {
        const [entered, setEntered] = useState(false)
        const ref = createRef()
        const ob = createRef()

        useEffect(() => {
            ob.current = new IntersectionObserver(entries => {
                if(entries && entries.length > 0){

                    const entry = entries[0]

                    if(entry.intersectionRatio > 0){
                        props?.entryView?.(entry)
                        setEntered(true)
                    }

                    
                }
            })

            ob.current.observe(ref.current)
        }, [])

        return <Wrapped innerRef={ref} entered={entered} {...props}/>
    }
}