// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1pCn1c2h79rJEVDnk5eBes{width:auto}._1pCn1c2h79rJEVDnk5eBes._1yBzWEIqAxjfQeQqxuD29u{background-color:#fff;padding:.05333rem .05333rem 0 .05333rem}._8rVUXt5d-vWCOt2txaATL{position:relative;overflow:hidden;display:block;text-decoration:none;color:#999}._8rVUXt5d-vWCOt2txaATL::after{content:'';margin-top:125%;display:block}._8rVUXt5d-vWCOt2txaATL ._3lVEL_YT2qqH4vip2MgNu9{position:absolute;top:calc(50% - 6px);left:0;width:100%;text-align:center;font-size:12px;color:#999}._8rVUXt5d-vWCOt2txaATL._1yBzWEIqAxjfQeQqxuD29u ._3lVEL_YT2qqH4vip2MgNu9{color:#fff}\n", "",{"version":3,"sources":["webpack://src/client/components/swiper/autowidth/style.module.scss","webpack://src/client/base.scss"],"names":[],"mappings":"AAEA,yBACE,UAAW,CADb,iDAGI,qBAAsB,CACtB,uCCFoC,CDGrC,wBAKD,iBAAkB,CAClB,eAAgB,CAChB,aAAc,CACd,oBAAqB,CACrB,UAAW,CALb,+BAQI,UAAW,CACX,eAAgB,CAChB,aAAc,CAVlB,iDAcI,iBAAkB,CAClB,mBAAoB,CACpB,MAAO,CACP,UAAW,CACX,iBAAkB,CAClB,cAAe,CACf,UAAW,CApBf,yEAyBM,UAAW","sourcesContent":["@import '../../../base.scss';\n\n.SwiperSlide {\n  width: auto;\n  &.Outline{\n    background-color: #fff;\n    padding: actual(2) actual(2) 0 actual(2);\n  }\n}\n\n\n.ViewMoreProduct {\n  position: relative;\n  overflow: hidden;\n  display: block;\n  text-decoration: none;\n  color: #999;\n\n  &::after {\n    content: '';\n    margin-top: 125%;\n    display: block;\n  }\n\n  .Text {\n    position: absolute;\n    top: calc(50% - 6px);\n    left: 0;\n    width: 100%;\n    text-align: center;\n    font-size: 12px;\n    color: #999;\n  }\n\n  &.Outline{\n    .Text{\n      color: #fff;\n    }\n  }\n}","$baseFontSize: 75; //基于视觉稿横屏尺寸/10得出的基准font-size\n$IMAGE_GEEKO_LTD: 'https://image.geeko.ltd';\n\n@function actual($px) {\n  @return 2 * $px / $baseFontSize * 1rem;\n}\n\n@function actualPc($px){\n  @return $px / 1920 * 100vw;\n}\n\n@function imageGeekoLtd($url){\n\t@return $IMAGE_GEEKO_LTD + $url;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SwiperSlide": "_1pCn1c2h79rJEVDnk5eBes",
	"Outline": "_1yBzWEIqAxjfQeQqxuD29u",
	"ViewMoreProduct": "_8rVUXt5d-vWCOt2txaATL",
	"Text": "_3lVEL_YT2qqH4vip2MgNu9"
};
module.exports = ___CSS_LOADER_EXPORT___;
