
import React, {useEffect, useState} from "react"

export default Wrapped => {

    return props => {
        const { items, onTab, selectedIndex } = props

        const [selectedItem, setSelectedItem] = useState()

        useEffect(() => {
            setSelectedItem( items?.[selectedIndex||0] || items?.[0])
        }, [items])

        const handleClick = (item, index) => {
            setSelectedItem(item)
            onTab(item, index)
        }


        const tabClick = (s, e) => {
            if(s.clickedIndex === s.activeIndex || s.clickedIndex === s.activeIndex+1){
                s.slidePrev(300)
            }else{
                 s.slideToClosest(300)
            }
        }


        return <Wrapped {...props} tabClick={tabClick} selectedItem={selectedItem} handleClick={handleClick} />
    }
}