import React from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import GroupImages from '../../../moduleable/components/images/index';
import { LazyLoadImage } from 'react-lazy-load-image-component'
import {Types} from './index'
import withObserver from '../../../../../pages/moduleable/modules/hoc/withObserver';
import {getStyleObjectFromString} from '../../../../../utils/style'
import NavigationSwiper from '../banner/components/navigation-swiper/index';

const PageImage = props => {
    const {images, sensors} = props
    return <GroupImages images={images} sensors={sensors} render={(item, index) => {
        return <LazyLoadImage alt={item?.title} src={item.src}/>
    }}/>
}



export default withObserver(props => {
    useStyles(classes)
    const {data, sensors, innerRef} = props
    const {groups, styledTitle} = data

    const styles = getStyleObjectFromString(data?.style)


    return <div ref={innerRef} id={data?.id} className={`${!data?.full? 'MaxWith': ''} ${classes.Container}`} style={{paddingTop: `${data?.marginTop}`, ...styles}}>
        {
            styledTitle && <div className={classes.Title}>
                <span dangerouslySetInnerHTML={{__html: styledTitle}}/>
            </div>
        }
        
        <div>
            <NavigationSwiper 
                className={classes.Swiper} 
                items={groups} 
                slidesPerView={1}
                slidesPerGroup={1}
                options={{
                    loop: true,
                    autoplay: {
                        delay: 3000,
                        disableOnInteraction:false
                    },
                }} 
            showBgNavigation
            render={(item, index) => {
                return <PageImage sensors={{
                    ...sensors,
                    type: '1',
                    position: `${sensors.position}-${index+1}`
                }} images={item}/>
            }}/>
        </div>
    </div>
})