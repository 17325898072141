import React from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import Product, { EmptyItem } from '../../../../../listing/components/item'

const EmptyProducts = props => {
    const { count = 10 } = props;

    return Array.from(new Array(count)).map((_, index) => <EmptyItem key={index} />)
}

export default props => {
    useStyles(classes)
    const { products, column, sensors, count, festival } = props

    return <div className={`${classes.Products} ${props.className}`}>
        {
            products?.length > 0 ? (
                products?.filter(p => !p.isCollection && !p.isSmartCard)?.map((product, index) => <Product festival={festival} key={`${product?.id}-${index}`} sensors={sensors} column={column} product={product} position={index} />)
            ) : <EmptyProducts count={count} />
        }
    </div>
}