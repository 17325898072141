// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._39phyitnVnhuw_p2NkyhVX{margin-bottom:16px}\n", "",{"version":3,"sources":["webpack://src/client/desktop/pages/moduleable/home/style.module.scss"],"names":[],"mappings":"AAAA,yBACI,kBAAmB","sourcesContent":[".PayPalMessage{\n    margin-bottom: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PayPalMessage": "_39phyitnVnhuw_p2NkyhVX"
};
module.exports = ___CSS_LOADER_EXPORT___;
