import React, {useCallback} from 'react'
import ImageContainer from '../../../../../components/image/image-container'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ListSwiperProducts from '../list/components/list-swiper-products/index'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import {getStyleObjectFromString} from '../../../../../utils/style'
import { Types } from './index'
import withSource from "../../../../../hocs/source-scoll"
import { getSensorsUrl } from '../../../../../utils/sensor'


const Image = withSource(props => {
	const { image, sensors, innerRef, position } = props
	const { width, height, title, href, src } = image
	const aspectRatio = width && height ? `${width} / ${height}` : undefined

	let ratio
	if(width && height){
		ratio = `${Math.floor(height*100/width)}%`
	}

    const clickHandle = useCallback(e => {
		e.preventDefault()
		const href = e.currentTarget?.href
		window.location.href = getSensorsUrl(href, {
			resourcepage_title: sensors?.title,
			resource_type: sensors?.type,
			resource_content: image.refId,
			resource_position: `${sensors?.position || 1}-${position + 1}`,
		})
	}, [])


	return <a
		ref={innerRef}
		data-position={`${sensors?.position || 1}-${position + 1}`}
		data-type={sensors?.type}
		data-content={image.refId || image.title}
		data-title={sensors?.title}
		href={href} title={title} onClick={clickHandle}>
			<div className={`${classes.Image} ${!ratio?classes.Static:''}`} style={{
				aspectRatio,
				paddingTop: ratio,
			}}>
				<img src={src} alt={title} />
			</div>
	</a>
})

export default ((props => {
    useStyles(classes)
    const { data, sensors, innerRef } = props
    const { images } = data;

    const styles = getStyleObjectFromString(data?.style)

    // const listStyles = getStyleObjectFromString(data?.listStyle);

    const aspectRatio = data?.width && data?.height ? `${data?.width} / ${data?.height}` : undefined

    const url = data?.href

    return <div ref={innerRef} id={data?.id} className={`${classes.ImageContainer} ${!data?.full? 'MaxWith': ''}`}  style={{ paddingTop: `${data?.marginTop}` }}>
        {
            data?.styledTitle && <div className={classes.Title}>
                <a href={url} dangerouslySetInnerHTML={{ __html: data.styledTitle }} />
            </div>
        }

        {
            data?.src && <div className={classes.ListImage}>
                <ImageContainer style={{aspectRatio}} href={url} position={0} sensors={{
                    ...sensors,
                    refId: data?.refId,
                    type: '1',
                }}>
                    <LazyLoadImage alt={data?.title} src={data?.src} />
                </ImageContainer>
            </div>
        }

        <div className={`${classes.Image5ListContainer}`} style={{...styles}}>
            <ListSwiperProducts 
                items={images} 
                showBgNavigation
                slidesPerView={5}
                slidesPerGroup={1}
                options={{
                    loop:false,
                }}
                render={(item, index)=>{
                    return <Image sensors={{
                        ...sensors,
                        refId: data?.refId,
                        type: '1',
                    }} column={data?.refId} image={item} position={index}/>
                }}
            />
        </div>
    </div>
}))