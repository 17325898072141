// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1f6OeXhD3MBbqrwo9F3di6{display:flex;flex-wrap:wrap}._1f6OeXhD3MBbqrwo9F3di6>*{width:20%;padding-left:5px;padding-right:5px;padding-bottom:30px}\n", "",{"version":3,"sources":["webpack://src/client/desktop/pages/moduleable/modules/list/components/list-products/style.module.scss"],"names":[],"mappings":"AAAA,yBACI,YAAa,CACb,cAAe,CAFnB,2BAIQ,SAAU,CACV,gBAAiB,CACjB,iBAAkB,CAClB,mBAAoB","sourcesContent":[".Products{\n    display: flex;\n    flex-wrap: wrap;\n    & > *{\n        width: 20%;\n        padding-left: 5px;\n        padding-right: 5px;\n        padding-bottom: 30px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Products": "_1f6OeXhD3MBbqrwo9F3di6"
};
module.exports = ___CSS_LOADER_EXPORT___;
