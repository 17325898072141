import React, { useState, useEffect, useMemo } from "react";
import ReactDOM from 'react-dom'
import cls from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";

import { Iconfont } from "../../../../components/icon/iconfont";
import { BigButton } from "../../../components/button";
import { BlackMask } from "../../../../components/mask";
import moment from 'moment'
import { fetchBlackIndexCoupons, fetchCustomer, fetchHasLuckDraw, fetchOrderConfirmCoupons, getCouponByCouponMouldId, subscribe } from "../../../../../api";
import { FormattedMessage, useIntl } from "react-intl";
import Cache from "../../../../../utils/cache";
import { useSelector } from "react-redux";
import { useBot, useLogin, useQuery } from "../../../../hooks/common";
import { CouponShare, GiftCard } from "../coupon-share-alert";
import { IMAGE_GEEKO_LTD } from "../../../../../constants";

export const OptionalCoupon = props => {
    useStyles(cls)
    const { coupon, isExpired, isBest } = props;

    return <div className={`${cls.CouponItem} ${cls.OptionalCoupon}`}>
        <div className={cls.DiscountCon}>
            <div className={cls.ContentBox}>
                {
                    coupon?.name && <div className={cls.TitleBox}>
                        <p className={cls.ItemTitle}>{coupon.name}</p>
                        {
                            isBest && <div className={cls.TitleBest}>
                                <FormattedMessage id="best_coupon" defaultMessage={'Best Coupon'} />
                            </div>
                        }
                    </div>
                }

                {
                    coupon?.condition && <p className={cls.Desc1}>
                        <span dangerouslySetInnerHTML={{ __html: coupon.condition }}></span>
                    </p>
                }
                {
                    coupon?.description && <p className={cls.Desc2}>
                        <span dangerouslySetInnerHTML={{ __html: coupon.description }}></span>
                    </p>
                }

                {
                    coupon?.infoMsg && <p className={cls.Desc2}>
                        <span dangerouslySetInnerHTML={{ __html: coupon.infoMsg }}></span>
                    </p>
                }

                {/* {
                    !isExpired && coupon?.willExpires &&
                    <span className={cls.ExpiresMessage}>
                        <FormattedMessage id="expires_soon" defaultMessage="Expires soon" />
                    </span>
                } */}
            </div>
            {/* {
                isExpired &&
                <div className={cls.ExpiredBox}>
                    <FormattedMessage id={"expired"}></FormattedMessage>
                </div>
            } */}
        </div>

        <div className={cls.MeetCon}>
            {
                coupon?.beginDate && coupon?.endDate && <p className={cls.Desc1}>
                    <span className={cls.Dot}>.</span>
                    <span>{moment(coupon?.beginDate).format('DD/MM/YYYY HH:mm')}~{moment(coupon?.endDate).format('DD/MM/YYYY HH:mm')}</span>
                </p>
            }

            {/* {
                coupon?.usageReminder && <p className={cls.Desc2}>
                    <span className={cls.Dot}>.</span>
                    <span>{coupon.usageReminder}</span>
                </p>
            } */}
        </div>
    </div>
}

export const GiftCardItem = props => {
    useStyles(cls)
    const { coupon, toShare, showShareBtn, hideTime } = props

    return (
        <div className={cls.GiftCardCoupon} style={{ ...props?.style }} onClick={() => toShare(coupon)}>
            <div className={cls.GiftCardBox}>
                <div className={cls.GiftBox}>
                    <img src={`${IMAGE_GEEKO_LTD}/chicme/20230413/gift.png`} alt="" />
                </div>
                <div className={cls.GiftCardInfo}>
                    {
                        coupon?.name && <p className={cls.ItemTitle}>{coupon.name}</p>
                    }

                    {
                        coupon?.condition && <p className={cls.Desc1}>
                            <span dangerouslySetInnerHTML={{ __html: coupon.condition }}></span>
                        </p>
                    }
                    {
                        coupon?.description && <p className={cls.Desc2}>
                            <span dangerouslySetInnerHTML={{ __html: coupon.description }}></span>
                        </p>
                    }

                    {
                        coupon?.infoMsg && <p className={cls.Desc2}>
                            <span dangerouslySetInnerHTML={{ __html: coupon.infoMsg }}></span>
                        </p>
                    }

                    {
                        showShareBtn && <div className={cls.ShareBtn}>
                            <FormattedMessage id="share" defaultMessage={"Share"} />
                        </div>
                    }
                    {
                        !hideTime && coupon?.beginDate && coupon?.endDate && <p className={cls.DateDesc}>
                            <span className={cls.Dot}>·</span>
                            <span>{moment(coupon?.beginDate).format('DD/MM/YYYY HH:mm')}~{moment(coupon?.endDate).format('DD/MM/YYYY HH:mm')}</span>
                        </p>
                    }
                </div>
            </div>
        </div>
    )
}


const CouponItem = props => {
    useStyles(cls)
    const { coupon, toShare, showShareBtn, hideTime } = props;

    return coupon?.giftCard ?
        <GiftCardItem coupon={coupon} toShare={toShare} showShareBtn={showShareBtn} hideTime={hideTime} /> :
        <div className={cls.CouponItem}>
            <div className={cls.DiscountCon}>
                {
                    coupon?.name && <p className={cls.ItemTitle}>{coupon.name}</p>
                }

                {
                    coupon?.condition && <p className={cls.Desc1}>
                        <span dangerouslySetInnerHTML={{ __html: coupon.condition }}></span>
                    </p>
                }
                {
                    coupon?.description && <p className={cls.Desc2}>
                        <span dangerouslySetInnerHTML={{ __html: coupon.description }}></span>
                    </p>
                }

                {
                    coupon?.infoMsg && <p className={cls.Desc2}>
                        <span dangerouslySetInnerHTML={{ __html: coupon.infoMsg }}></span>
                    </p>
                }
            </div>

            <div className={cls.MeetCon}>
                {
                    coupon?.beginDate && coupon?.endDate && <p className={cls.Desc1}>
                        <span className={cls.Dot}>.</span>
                        <span>{moment(coupon?.beginDate).format('DD/MM/YYYY HH:mm')}~{moment(coupon?.endDate).format('DD/MM/YYYY HH:mm')}</span>
                    </p>
                }

                {/* {
                coupon?.usageReminder && <p className={cls.Desc2}>
                    <span className={cls.Dot}>.</span>
                    <span>{coupon.usageReminder}</span>
                </p>
            } */}
            </div>
        </div>
}

const CouponAlert = props => {
    useStyles(cls)
    const { coupons, handleClose, orderId, setShowShare, isShare, errorCode, orderConfirmTitle, lotteryConfig, unsubscribeCustomer } = props;
    const global = useSelector(state => state.global);
    const intl = useIntl();
    const query = useQuery()

    const isLogin = useMemo(() => {
        // isRefresh 是app登录完会加在链接上的参数
        return query?.isRefresh || !!global?.user?.id
    }, [global?.user?.id])

    useEffect(() => {
        let sensorData = {
            resourcepage_title: 'coupon',
            resource_position: '1',
            resource_type: '22',
            resource_content: unsubscribeCustomer ? "resubscribe" : "coupon"
        };

        if (orderId) {
            sensorData = {
                resourcepage_title: 'coupon',
                resource_position: '1',
                resource_type: '22',
                resource_content: "coupon",
                page_title: 'order_confirm',
                page_sort: 'order_confirm'
            };
        }

        if (window.GeekoSensors) {
            if (isShare && !errorCode) {
                // 首页领取分享优惠券弹窗曝光
                window.GeekoSensors.Track("Popup_GiftCard_Exposure")
            } else {
                window.GeekoSensors.Track("PitPositionExposure", sensorData)
            }
        }
    }, []);

    const couponMessage = useMemo(() => {
        if (!!global.user?.id) {
            if (isShare) {
                return <React.Fragment>
                    <div>{intl.formatMessage({ id: "congratulations", defaultMessage: "Congratulations" })}!</div>
                    <div>{intl.formatMessage({ id: 'gift_card_receive', defaultMessage: 'An exclusive gift card has been placed in your coupon pack' })}!</div>
                </React.Fragment>
            } else if (orderId) {
                // return `${intl.formatMessage({ id: "congratulations", defaultMessage: "Congratulations" })}!${intl.formatMessage({ id: "received_vip_exclusive_discount", defaultMessage: "You've received VIP Exclusive Discounts" })}`
                // return `${intl.formatMessage({id: 'got_extra_chance_to_win'})}`
                return <React.Fragment><div dangerouslySetInnerHTML={{ __html: orderConfirmTitle }}></div></React.Fragment>
            } else if (unsubscribeCustomer) {
                return <FormattedMessage id="unsubscribe_message" defaultMessage="Resubscribe with {email} on {website} and get coupons." values={{ email: unsubscribeCustomer.email, website: global?.config?.name }} />
            } else {
                return `${intl.formatMessage({ id: "received_following_discount", defaultMessage: "You've received the Following Discounts" })}`;
            }
        } else {
            if (isShare) {
                return <React.Fragment>
                    <div>{intl.formatMessage({ id: "congratulations", defaultMessage: "Congratulations" })}!</div>
                    <div>{intl.formatMessage({ id: 'get_gift_card_from_share', defaultMessage: "You'll get an exclusive gift card shared by your friends" })}!</div>
                </React.Fragment>
            }
            return `${intl.formatMessage({ id: "sing_receive_discount_now", defaultMessage: "Sign in to receive the following discounts now!" })}`
        }
    }, [global?.user?.id, orderId, orderConfirmTitle])

    const handleClick = (type) => {
        let sensorData = {
            resourcepage_title: 'coupon',
            resource_position: '1',
            resource_type: '22',
            resource_content: unsubscribeCustomer ? "resubscribe" : "coupon"
        };
        if (orderId) {
            sensorData = {
                resourcepage_title: 'coupon',
                resource_position: '1',
                resource_type: '22',
                resource_content: "coupon",
                page_title: 'order_confirm',
                page_sort: 'order_confirm'
            };
        }
        if (window.GeekoSensors) {
            if (orderId && coupons?.[0]?.giftCard && type != 'lottery') {
                window.GeekoSensors.Track("GiftCardShare", { button: 'clickshare' })
            } if (isShare) {
                window.GeekoSensors.Track("Popup_GiftCard", { button: isLogin ? 'goshopping' : 'collect' })
            } else {
                window.GeekoSensors.Track("PitPositionClick", sensorData)
            }
        }

        if (!!global.user?.id) {
            if (type == "lottery") {
                if (window.parent?.linkHandle) {
                    let url = '/?show_lottery=1'
                    window.parent.linkHandle(url);
                } else {
                    handleClose();
                }
            } else {
                if (orderId && coupons?.[0]?.giftCard) {
                    setShowShare(coupons?.[0])
                } else {
                    handleClose();
                }
            }

            if(unsubscribeCustomer){
                subscribe(unsubscribeCustomer.email)
            }
        } else {
            if (type == "lottery") {
                if (window.parent?.linkHandle) {
                    let unLoginUrl = `/i/login?redirectUrl=${encodeURIComponent('/?show_lottery=1')}`
                    window.parent.linkHandle(unLoginUrl);
                } else {
                    let url = window.location.href;
                    let href;
                    if (url?.indexOf("?") != -1) {
                        if (url?.split("?")[1]) {
                            href = `${url}&show_lottery=1`;
                        } else {
                            href = `${url}show_lottery=1`;
                        }
                    } else {
                        href = `${url}?show_lottery=1`;
                    }
                    window.location.href = `/i/login?redirectUrl=${encodeURIComponent(href)}`;
                    return
                }
            } else {
                if (window.parent?.linkHandle) {
                    // let unLoginUrl = `/i/login?redirectUrl=${encodeURIComponent('/?show_lottery=1')}`
                    // window.parent.linkHandle(unLoginUrl);

                    let url = window.parent?.location.href;
                    let href;
                    if (url?.indexOf("?") != -1) {
                        if (url?.split("?")[1]) {
                            href = `${url}&isRefresh=1${orderId && coupons?.[0]?.giftCard ? '&showShareMask=1' : ''}`;
                        } else {
                            href = `${url}isRefresh=1${orderId && coupons?.[0]?.giftCard ? '&showShareMask=1' : ''}`;
                        }
                    } else {
                        href = `${url}?isRefresh=1${orderId && coupons?.[0]?.giftCard ? '&showShareMask=1' : ''}`;
                    }
                    window.parent.linkHandle(`/i/login?redirectUrl=${encodeURIComponent(href)}`);
                } else {
                    let url = window.location.href;
                    let href;
                    if (url?.indexOf("?") != -1) {
                        if (url?.split("?")[1]) {
                            href = `${url}&isRefresh=1${orderId && coupons?.[0]?.giftCard ? '&showShareMask=1' : ''}`;
                        } else {
                            href = `${url}isRefresh=1${orderId && coupons?.[0]?.giftCard ? '&showShareMask=1' : ''}`;
                        }
                    } else {
                        href = `${url}?isRefresh=1${orderId && coupons?.[0]?.giftCard ? '&showShareMask=1' : ''}`;
                    }
                    window.location.href = `/i/login?redirectUrl=${encodeURIComponent(href)}`;
                    return
                }
            }
        }
    }


    return errorCode ?
        <ErrorMask errorCode={errorCode} intl={intl} onClose={handleClose} /> :
        <div className={cls.CouponAlert}>
            <div className={cls.Container}>
                <Iconfont className={cls.Close} onClick={handleClose}>&#xe7c9;</Iconfont>

                <div className={`${cls.Title} ${cls.GlobalPadding} ${isShare && cls.TitleUnLimitHeight}`}>
                    {couponMessage}
                </div>

                {
                    !isShare ?
                        <div className={cls.CouponListCon}>
                            <ul className={cls.CouponList}>
                                {
                                    coupons?.map((coupon, index) => (
                                        <li key={coupon?.id + index} className={`${coupon?.giftCard ? cls.ShareLi : ''}`}>
                                            <CouponItem coupon={coupon} showShareBtn={false} />
                                        </li>
                                    ))
                                }
                            </ul>
                        </div> :
                        <div className={`${cls.GiftCard} ${cls.GiftCardOnly}`}>
                            <CouponItem coupon={coupons?.[0]} isShare={true} hideTime={true} />
                        </div>
                }

                <div className={`${cls.MainBtn} ${cls.GlobalPadding}`}>
                    {
                        unsubscribeCustomer ? <React.Fragment>
                            <BigButton onClick={handleClick}>
                                <FormattedMessage id="subscribe_and_get" defaultMessage="Subscribe & Get Coupons" />
                            </BigButton>
                        </React.Fragment> : <React.Fragment>
                            {
                                !(orderId && !coupons?.[0]?.giftCard && lotteryConfig) &&
                                <BigButton onClick={handleClick}>
                                    {
                                        orderId ?
                                            (coupons?.[0]?.giftCard ?
                                                <FormattedMessage id="collect_share" defaultMessage="Collect & Share" /> :
                                                <FormattedMessage id="collect_all" defaultMessage="Collect All" />) :
                                            isLogin ?
                                                <FormattedMessage id="go_shopping" defaultMessage="Go Shopping" /> :
                                                isShare ?
                                                    <FormattedMessage id="collect_now" defaultMessage="Collect Now" /> :
                                                    <FormattedMessage id="collect_all" defaultMessage="Collect All" />
                                    }
                                </BigButton>
                            }

                            {
                                lotteryConfig && orderId &&
                                <BigButton style={{ marginTop: 10 }} onClick={() => handleClick('lottery')}>
                                    <FormattedMessage id="collect_and_draw" defaultMessage="Collect & Draw now" />
                                </BigButton>
                            }
                            {/* <span className={cls.BlackIcon}></span> */}
                        </React.Fragment>
                    }
                </div>
            </div>
        </div>
}

const ErrorMask = props => {
    const { errorCode, onClose, intl } = props
    useStyles(cls)

    const errorMsg = useMemo(() => {
        let title, tip, imgUrl, buttonMsg
        // 1 链接过期 2：分享券已失效，3：已达到最大领取数量
        switch (errorCode) {
            case 1:
            case "1":
                title = intl.formatMessage({ id: 'link_expired', defaultMessage: 'Sorry, this link has expired' })
                tip = intl.formatMessage({ id: 'link_valid_for_7_days', defaultMessage: 'It was only valid for 7 days after it was shared.' })
                imgUrl = `${IMAGE_GEEKO_LTD}/chicme/20230516/coupon-share-link-expired-1.svg`
                buttonMsg = intl.formatMessage({ id: 'go_shopping', defaultMessage: 'Go Shopping' })
                break
            case 2:
            case "2":
                title = intl.formatMessage({ id: 'share_coupon_expired', defaultMessage: 'Sorry, the Shared Coupon has Expired' })
                imgUrl = `${IMAGE_GEEKO_LTD}/chicme/20230516/coupon-share-link-expired.svg`
                buttonMsg = intl.formatMessage({ id: 'go_shopping', defaultMessage: 'Go Shopping' })
                break
            case 3:
            case "3":
                title = intl.formatMessage({ id: 'reach_coupon_limit', defaultMessage: 'Sorry, You\'ve Reached the Limit for Coupon Claims' })
                imgUrl = `${IMAGE_GEEKO_LTD}/chicme/20230516/coupon-share-time-limit.svg`
                buttonMsg = intl.formatMessage({ id: 'go_shopping', defaultMessage: 'Go Shopping' })
                break
            case 4:
            case "4":
                title = intl.formatMessage({ id: 'user_time_limit', defaultMessage: 'Sorry,You\'ve Already Received the Coupon.Cannot Be Claimed Again' })
                imgUrl = `${IMAGE_GEEKO_LTD}/chicme/20230516/coupon-share-link-recived.svg`
                buttonMsg = intl.formatMessage({ id: 'go_shopping', defaultMessage: 'Go Shopping' })
                break
        }

        return { title, tip, imgUrl, buttonMsg }
    }, [errorCode])

    return (<React.Fragment>
        <div className={cls.ErrorMask}>
            <Iconfont className={cls.Close} onClick={onClose}>&#xe7c9;</Iconfont>

            <div className={`${cls.Title} ${(errorCode == 4 || errorCode == "4") && cls.MinTitle}`}>
                {errorMsg?.title || ''}
            </div>

            <div className={cls.Tip}>
                {errorMsg?.tip || ''}
            </div>

            <div className={cls.Img}>
                <img src={errorMsg?.imgUrl || ''} alt="" />
            </div>

            <div className={cls.Button} onClick={onClose}>
                <BigButton>
                    {errorMsg?.buttonMsg || ''}
                </BigButton>
            </div>
        </div>
    </React.Fragment>)
}

export default props => {
    useStyles(cls);
    const { orderId } = props;
    const query = useQuery();
    const [coupons, setCoupons] = useState(null);
    const [show, setShow] = useState(false);
    const [showShare, setShowShare] = useState(false);
    const [errorCode, setErrorCode] = useState(); // 领取分享优惠券时可能会报错的弹窗
    const [orderConfirmTitle, setOrderConfirmTitle] = useState(); // 下单弹窗文案 html
    const [lotteryConfig, setLotteryConfig] = useState(false); // 是否开了抽奖
    const isBot = useBot()
    const [unsubscribeCustomer, setUnsubscribeCustomer] = useState(null)
    const isLogin = useLogin()

    useEffect(async () => {
        try {
            if (query?.showShareMask && orderId) {
                orderCouponAlert(orderId, true);
            } else if (query?.couponMouldId || query?.couponShareErrorCode) {
                // 分享优惠券弹窗
                if (query?.couponMouldId) {
                    shareCouponAlert()
                }
                setShow(true)
                setErrorCode(Cache.get("couponShareErrorCode") || query?.couponShareErrorCode)
            } else if (orderId) {
                orderCouponAlert(orderId);
            } else {
                indexCouponAlert();
            }
        } catch (error) {
            console.log('error', error)
        }
    }, [])


    // app或者嵌入iframe的地方调用外部关闭的通用方法
    const iframeDispose = () => {
        window.parent?.closeHandle?.();
        return;
    }

    const orderCouponAlert = async (orderId, flag) => {
        let response = await fetchOrderConfirmCoupons(orderId);
        if (response?.code === 200 && response?.result?.coupons?.length > 0) {
            setShow(true);
            setCoupons(response?.result?.coupons);
            setOrderConfirmTitle(response?.result?.title);
            flag && response?.result?.coupons?.[0]?.giftCard && setShowShare(response?.result?.coupons?.[0]);
        } else {
            iframeDispose();
        }
        let response1 = await fetchHasLuckDraw();
        if (response1?.code === 200 && response1?.result) {
            setLotteryConfig(response1?.result || false)
        } else {
            setLotteryConfig(false)
        }
    }

    const indexCouponAlert = async () => {
        const couponAlert = Cache.get('black-coupons');
        const couponAlert2 = Cache.get('black-coupons2');

        if ((query?.isRefresh && !couponAlert2) || (!query?.show_lottery && !couponAlert)) {
            if (query?.isRefresh) {
                Cache.set('black-coupons', 1)
                Cache.set('black-coupons2', 1)
            } else {
                Cache.set('black-coupons', 1)
            }


            const couponPromise = fetchBlackIndexCoupons()
            const customerPromise = isLogin ? fetchCustomer().catch(() => null) : Promise.resolve(null)
            // const customerPromise = isLogin ? Promise.resolve({
            //    result: {
            //     email: 'test@13.com',
            //     subscription: false
            //    },
            //    code: 200
            // }) : Promise.resolve(null)
            const [couponResponse, customerResponse] = await Promise.all([couponPromise, customerPromise])


            if (couponResponse?.code === 200 && couponResponse?.result) {

                if (customerResponse?.code === 200 && customerResponse.result && !!!customerResponse.result.subscription) {
                    // 未订阅邮件
                    setUnsubscribeCustomer(customerResponse.result)
                }

                setShow(true);
                setCoupons(couponResponse.result);
            } else {
                iframeDispose();
            }
        } else {
            iframeDispose();
        }
    }

    const shareCouponAlert = async () => {
        if ((query?.isRefresh) || !query?.show_lottery) {
            const res = await getCouponByCouponMouldId({ couponMouldId: query?.couponMouldId })
            if (res && res?.code == 200 && res?.result) {
                // 根据id获取coupon信息
                setShow(true);
                setCoupons([res?.result])
            } else {
                iframeDispose();
            }
        }
    }

    const handleClose = () => {
        if (errorCode) {
            failLink()
        }
        setShow(false);
        iframeDispose();
    }

    const handleCoseToDraw = () => {
        setShow(false);
        iframeDispose();
    }

    const failLink = () => {
        Cache.remove("couponShareErrorCode")
        window.location.href = '/'
        return
    }

    const closeShareMask = () => {
        setShowShare(false)
    }

    return <React.Fragment>
        {
            !isBot && show && (coupons?.length > 0 || query?.couponShareErrorCode) && ReactDOM.createPortal(<React.Fragment>
                <CouponAlert
                    {...props}
                    unsubscribeCustomer={unsubscribeCustomer}
                    errorCode={errorCode}
                    isShare={query?.couponMouldId}
                    setShowShare={setShowShare}
                    handleClose={handleClose}
                    lotteryConfig={lotteryConfig}
                    orderConfirmTitle={orderConfirmTitle}
                    coupons={coupons} />
                <BlackMask onClick={handleClose} />

                {
                    showShare && <React.Fragment>
                        <CouponShare
                            coupon={showShare}
                            onClose={() => closeShareMask()}
                            fetchParams={{ urlCode: 'CPN0', params: JSON.stringify({ couponId: showShare?.id, couponMouldId: showShare?.couponMouldId }) }}
                        />
                        <BlackMask style={{ zIndex: 11 }} onClick={() => closeShareMask()} />
                    </React.Fragment>
                }
            </React.Fragment>, typeof document !== 'undefined' ? document.getElementById('root') : null)
        }
    </React.Fragment>
}