import React from 'react'
import withList from '../../../../../pages/moduleable/modules/list/hoc/withList'
import ImageContainer from '../../../../../components/image/image-container'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Product from '../../../../../desktop/pages/listing/components/item/index';
import ListSwiperProducts from '../list/components/list-swiper-products/index'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import {getStyleObjectFromString} from '../../../../../utils/style'
import { Types } from './index'
import withSource from "../../../../../hocs/source-scoll"
import { FormattedMessage } from 'react-intl'

const Blog = withSource(props => {
    useStyles(classes)
    const { image, sensors, innerRef, position } = props
    const { width, height, title, description } = image || {}
    const aspectRatio = width && height ? `${width} / ${height}` : undefined

    return <a className={classes.Blog}
        data-position={`${sensors?.position || 1}-${(position || 0) + 1}`}
        data-type={sensors?.type}
        data-content={image?.refId}
        data-title={sensors?.title}
        ref={innerRef}
        href={`/i/blog?messageId=${image.messageId}`}
    >
        <div className={classes.BlogImage} style={{
            aspectRatio,
        }}>
            <LazyLoadImage src={image.imgUrl} alt={title} />
        </div>
        <div className={classes.BlogTitle}>
            <span dangerouslySetInnerHTML={{ __html: title }} />
        </div>

        <div className={classes.BlogDescription}>
            <span dangerouslySetInnerHTML={{ __html: description }}></span>
        </div>
        <div className={classes.LearnMore}>
            <a><FormattedMessage id="learn_more" defaultMessage={'Learn More'} />{' >'}</a>
        </div>
    </a>
})

export default ((props => {
    useStyles(classes)
    const { data, sensors, innerRef } = props
    const { blogs } = data;

    const styles = getStyleObjectFromString(data?.style)

    const listStyles = getStyleObjectFromString(data?.listStyle);

    const url = data?.href

    return <div ref={innerRef} id={data?.id} className={`${classes.BlogContainer} ${!data?.full? 'MaxWith': ''}`}  style={{ paddingTop: `${data?.marginTop}`, ...styles }}>
        {
            data?.styledTitle && <div className={classes.Title}>
                <a href={url} dangerouslySetInnerHTML={{ __html: data.styledTitle }} />
            </div>
        }

        <div className={`${classes.ListContainer}`} style={{...listStyles}}>
            <ListSwiperProducts 
                items={blogs} 
                showBgNavigation
                slidesPerView={4}
                slidesPerGroup={1}
                options={{
                    loop:false
                }}
                render={(item, index)=>{
                    return <Blog sensors={{
                        ...sensors,
                        refId: data?.refId,
                        type: `${Types.TYPES_1}`
                    }} column={data?.refId} image={item} position={index}/>
                }}
            />
        </div>
    </div>
}))