import React from 'react'
import ListSwiperProducts from '../list/components/list-swiper-products/index'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import {getStyleObjectFromString} from '../../../../../utils/style'
import { Types } from './index'
import withSource from "../../../../../hocs/source-scoll"

const Vendor = withSource(props => {
    useStyles(classes)
    const { item, sensors, innerRef, position } = props
    const { width, height, src, href } = item || {}
    const aspectRatio = width && height ? `${width} / ${height}` : undefined

    return <a
        data-position={`${sensors?.position || 1}-${(position || 0) + 1}`}
        data-type={sensors?.type}
        data-content={item?.refId}
        data-title={sensors?.title || item?.title}
        ref={innerRef}
        href={href}
        className={classes.VendorLink}
    >
        <img src={src} alt={item?.title} />
    </a>
})

export default ((props => {
    useStyles(classes)
    const { data, sensors, innerRef } = props
    const { verndors } = data;

    const styles = getStyleObjectFromString(data?.style)

    const listStyles = getStyleObjectFromString(data?.listStyle);


    return <div ref={innerRef} id={data?.id} className={`${classes.VerndorsContainer} ${!data?.full? 'MaxWith': ''}`}  style={{ paddingTop: `${data?.marginTop}`, ...styles }}>

        <div className={classes.ListContainer} style={{...listStyles}}>
            <ListSwiperProducts 
                items={verndors} 
                slidesPerView={6}
                slidesPerGroup={1}
                className={classes.SwiperContainer}
                prevStyles={{container:{top: '50%',transform: 'translateY(-50%)'},icon:{fontSize:24}}}
                nextStyles={{container:{top: '50%',transform: 'translateY(-50%)'},icon:{fontSize:24}}}
                options={{
                    loop:false
                }}
                render={(item, index)=>{
                    return <Vendor sensors={{
                        ...sensors,
                        refId: data?.refId,
                        type: `${Types.TYPES_1}@${data?.refId}`
                    }} column={data?.refId} item={item} position={index}/>
                }}
            />
        </div>
    </div>
}))