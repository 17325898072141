import React from 'react'

import Image from './image'
import List from './list'
import Lottery from './lottery'
import SigIn from './sign-in'
import Tips from './tips';
import Gals from './gals';
import Collection from './collection';
import Banner from './banner';
import Blog from './blog'
import Vendors from './vendors'
import ImageGroup from './image-group';
import FestivalImages from './festival/images'
import Video from './video'

import ErrorWrapper from '../../../../error-wrapper'

const linkedSelect = (funcs, type) => {
    let element
    let index = 0
    while (!element && index < funcs.length) {
        element = funcs[index](type)
        index++
    }
    return element
}

const funcs = [
    Image,
    List,
    Lottery,
    SigIn,
    Tips,
    Gals,
    Collection,
    Banner,
    Blog,
    Vendors,
    ImageGroup,
    FestivalImages,
    Video
]

export default props => {
    const { data, sensors, abTest, entryView } = props
    const shouldRenderData = data?.sort((d1, d2) => d1.sort - d2.sort)?.filter(d => d.show)

    // console.log(shouldRenderData)

    // shouldRenderData.unshift({
    //     id:'list-8', 
    //     show: true,
    //     sort: -2,
    //     type: 'list-8', // 组件类型
    //     interface: '3', // 3是collection 5是category
    //     relatedId: '1o6X6B4d1a6K0C8g6o7T2M2O0M', // 产品所属collection id或者category id
    //     backgroundImage: 'https://image.geeko.ltd/chicme/20221104/self-photo-bg1.jpg', // 背景图
    //     href: "/i/index", // 图片，view more 点击去的页面
    //     src: 'https://image.geeko.ltd/chicme/20221104/self-photo-img1.png', // 内容的图片链接 
    //     typeRight: false, // 文字位置 true在右边， false在左边
    //     productTitle: 'Go-ahead of the fashion',// 描述标题
    //     productTitleStyle: '', // 描述标题的样式 行内样式写法
    //     productDes: 'A statement coat set makes it easy to stay stylish without looking boring', // 描述文案
    //     productDesStyle: '', // 描述文案的样式 行内样式写法
    //     textAlign: 'left', // 文字对齐方式 默认左对齐
    //     full: true,
    // })

    return <React.Fragment>
        {
            shouldRenderData?.map((d, index) => {
                const Element = linkedSelect(funcs, d.type)
                return Element && <ErrorWrapper>
                    <Element {...props} abTest={abTest} key={`${d.id || index}-${index}`} data={d} sensors={{...sensors, position: index+1}}/>
                </ErrorWrapper>
            })
        }
    </React.Fragment>
}