import React from 'react'
import {Iconfont} from '../icon/iconfont'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
export const BigButton = props => {
	useStyles(classes)
	return <button  {...props} disabled={props.loading || props.disabled} className={`${classes.BigButton} ${props.outlined? classes.Outlined: ''} ${(props.loading||props.disabled)?classes.Loading:''} ${props.className}`}>
		{
			props.loading && <Iconfont className={classes.LoadIcon}>&#xe62d;</Iconfont>
		}
		{
			props.children
		}
	</button>
}