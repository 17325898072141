import React, { useCallback, useState } from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import GroupImages from '../../../moduleable/components/images/index'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import withObserver from '../../../../../pages/moduleable/modules/hoc/withObserver'
import withSource from '../../../../../hocs/source-scoll'
import { getStyleObjectFromString } from '../../../../../utils/style'
import { getSensorsUrl } from '../../../../../utils/sensor'
import { FullSwiper } from '../../../../../pages/moduleable/components/swiper'

const PageImages = props => {
    const { images, sensors } = props
    return <GroupImages images={images} sensors={sensors} render={(item, index) => {
        return <LazyLoadImage alt={item?.title} src={item.src} />
    }} />
}

const Image = withSource(props => {
    const { image, sensors, innerRef, position } = props
    const { width, height, title, href, appHref } = image
    const aspectRatio = width && height ? `${width} / ${height}` : undefined

    const imagePosition = `${sensors?.position || 1}-${position + 1}`

    let ratio
    if (width && height) {
        ratio = `${Math.floor(height * 100 / width)}%`
    }


    const clickHandle = useCallback(e => {
        e.preventDefault()
        const href = e.currentTarget?.href
        window.location.href = getSensorsUrl(href, {
            resourcepage_title: sensors?.title,
            resource_type: sensors?.type,
            resource_content: image.refId,
            resource_position: imagePosition,
        })
    }, [])

    return <a
        ref={innerRef}
        data-position={imagePosition}
        data-type={sensors?.type}
        data-content={image.refId || image.title}
        data-title={sensors?.title}
        onClick={clickHandle}
        href={href} title={title}>
        <div className={`${classes.Image} ${!ratio ? classes.Static : ''}`} style={{
            aspectRatio,
            paddingTop: ratio,
        }}>
            <LazyLoadImage alt={image?.title} src={image.src} />
        </div>
    </a>
})

const SwiperImages = props => {
    useStyles(classes)
    const { images, sensors } = props

    const [showNavi, setShowNavi] = useState(false)
    const [swiper, setSwiper] = useState(null)

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return `
                <span class="${className}"></span>
            `
        },
        clickableClass: classes.SwiperPagination,
        bulletActiveClass: classes.Active,
        bulletClass: classes.Pagegation
    }

    const pageHandle = type => {
        if(type == 'next'){
            swiper?.slideNext()
        } else{
            swiper?.slidePrev()
        }
    }

    return <div className={classes.ImageSwiper} onMouseOver={e => setShowNavi(true)} onMouseLeave={e => {setShowNavi(false)}}>
        <FullSwiper className={classes.NormalSwiper} items={images} options={{
            pagination: images?.length > 1 ? pagination : false,
            loop: true,
            autoplay: {
                delay: 3000,
            },
            onSwiper: setSwiper
        }} render={(item, index) => {
            return <Image sensors={sensors} image={item} position={index} />
        }} />

        {
            images?.length > 1 && showNavi && <div>
                <span className={classes.Prev} onClick={() => pageHandle()}>
                    <span>&lsaquo;</span>
                </span>
                <span className={classes.Next} onClick={() => pageHandle('next')}>
                    <span>&rsaquo;</span>
                </span>
            </div>
        }

    </div>

}



export default withObserver(props => {
    useStyles(classes)
    const { data, sensors, innerRef } = props
    const { groups, styledTitle } = data


    const styles = getStyleObjectFromString(data?.style)


    return <div ref={innerRef} id={data?.id} className={`${!data?.full? 'MaxWith': ''} ${classes.Container}`} style={{paddingTop: `${data?.marginTop}`, ...styles}}>
        {
            styledTitle && <div className={classes.Title}>
                <span dangerouslySetInnerHTML={{ __html: styledTitle }} />
            </div>
        }

        <div className={classes.Groups}>

            {
                groups?.map?.((group, index) => group?.type === '1' ? <div key={index} style={{
                    width: isNaN(group.percent) ? group.percent : `${Number(group.percent) * 100}%`
                }}>
                    <SwiperImages sensors={{
                        ...sensors,
                        type: '1',
                        position: `${sensors.position}-${index + 1}`
                    }} images={group?.images} />
                </div> : <div key={index} style={{
                    width: isNaN(group.percent) ? group.percent : `${Number(group.percent) * 100}%`
                }}>
                    <PageImages sensors={{
                        ...sensors,
                        type: '1',
                        position: `${sensors.position}-${index + 1}`
                    }} images={group?.images} />
                </div>)
            }

        </div>
    </div>
})