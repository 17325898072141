import React, { useEffect, useState, useCallback } from 'react'
import ImageContainer from '../../../../../components/image/image-container'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ListProducts from './components/list-products'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import withGroup from '../../../../../pages/moduleable/modules/list/hoc/withGroup'
import { FormattedMessage } from 'react-intl'
import { Types } from './index'
import withObserver from '../../../../../pages/moduleable/modules/hoc/withObserver'
import { getStyleObjectFromString } from '../../../../../utils/style'
import withSource from '../../../../../hocs/source-scoll'
import { getSensorsUrl } from '../../../../../utils/sensor'


const Tab = withSource(props => {
    useStyles(classes)
    const {selectedItem, innerRef, sensors, item, handleClick, position} = props
    return <span
        ref={innerRef}
        data-position={`${sensors?.position || 1}-${(position || 0) + 1}`}
        data-type={sensors?.type}
        data-content={item?.refId ||item.title || item.name}
        data-title={sensors?.title}
        onMouseOver={() => { handleClick(item, position) }} 
        className={`${classes.Tab} ${selectedItem?.id === item?.id ? classes.Selected : ''}`}
    ><span>{item.title || item.name}</span></span>
})

const Tabs = (props => {
    useStyles(classes)
    const { items, onTab } = props
    return <div className={`${classes.Tabs} ${classes.Group8Tabs}`}>
        {
            items?.map((item, index) => <Tab handleClick={item=>onTab(item, index)} key={index} {...props} item={item} position={index}/>)
        }
    </div>
})


export default withGroup(withObserver(props => {
    useStyles(classes)
    const { collections, data, sensors, innerRef } = props
    const [collection, setCollection] = useState()
    const [position, setPosition] = useState(1)

    const styles = getStyleObjectFromString(data?.style)

    const listStyles = getStyleObjectFromString(data?.listStyle);

    useEffect(() => {
        setCollection(collections?.[0])
    }, [collections])

    const aspectRatio = data?.width && data?.height ? `${data?.width} / ${data?.height}` : undefined

    const clickHandle = e => {
		e.preventDefault()
		const href = e.currentTarget?.href
		window.location.href = getSensorsUrl(href, {
			resourcepage_title: sensors?.title,
			resource_type: '1',
			resource_content: collection?.refId || collection?.id,
			resource_position: `${sensors?.position}-${position}`,
		})
	}

   
    return <div ref={innerRef} id={data?.id} className={ `${classes.ListContainer} ${!data?.full? 'MaxWith': ''}` } style={{ paddingTop: `${data?.marginTop}`, ...styles }}>

        <div className={classes.TitleContainer}>
            {
                data?.styledTitle && <div className={classes.Title}>
                    <span dangerouslySetInnerHTML={{ __html: data.styledTitle }} />
                </div>
            }

            <div className={classes.HdViewAll}>
                <a onClick={clickHandle} href={collection?.href}><FormattedMessage id="view_all" defaultMessage="View All" />{' >'}</a>
            </div>
        </div>
        

        {
            data?.src && <ImageContainer href={data?.href} style={{ marginBottom: 12, aspectRatio }} sensors={{
                ...sensors,
                refId: data?.refId,
                type: '1',
            }}>
                <LazyLoadImage alt={data?.title} src={data?.src} />
            </ImageContainer>
        }

        <div>
            <Tabs
                selectedItem={collection}
                sensors={
                    {
                        ...sensors,
                        type: '1',
                    }
                }
                items={collections} onTab={
                    (item, index) => {
                        setCollection(item)
                        setPosition(index+1)
                    }
                } />
        </div>

        <div className={classes.ListContainer2} style={{...listStyles}}>
            <ListProducts 
                className={`${classes.Products} ${classes.RowSix}`} 
                sensors={
                    {
                        resourcepage_title: sensors?.title,
                        resource_position: `${sensors?.position}-${position}`,
                        resource_content: collection?.refId || collection?.id,
                        resource_type: '1',
                    }
                } 
            column={collection?.refId || collection?.id} 
            products={collection?.products} 
            count={12}
        />
        </div>
    </div>
}))