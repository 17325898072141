import React, { useCallback } from 'react'
import withList from '../../../../../pages/moduleable/modules/list/hoc/withList'
import ImageContainer from '../../../../../components/image/image-container'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Product, { ViewMoreProductItem } from '../../../../../desktop/pages/listing/components/item/preorder-item';
import ListSwiperProducts from './components/list-swiper-products'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { getStyleObjectFromString } from '../../../../../utils/style'
import { Types } from './index'
import withObserver from '../../../../../pages/moduleable/modules/hoc/withObserver'
import { FormattedMessage } from 'react-intl'
import { getSensorsUrl } from '../../../../../utils/sensor';

export default withList(withObserver(props => {
    useStyles(classes)
    const { products, data, sensors, innerRef } = props

    const styles = getStyleObjectFromString(data?.style)

    const listStyles = getStyleObjectFromString(data?.listStyle);

    const dark = !!styles?.backgroundColor || !!styles?.backgroundImage

    const url = data?.href

    const aspectRatio = data?.width && data?.height ? `${data?.width} / ${data?.height}` : undefined

    const clickHandle = useCallback(e => {
        e.preventDefault()
        const href = e.currentTarget?.href
        window.location.href = getSensorsUrl(href, {
            resourcepage_title: sensors?.title,
            resource_type: '1',
            resource_content: data?.refId,
            resource_position: sensors?.position,
        })
    }, [])

    return <div ref={innerRef} id={data?.id} className={`${classes.ListContainerF} ${!data?.full ? 'MaxWith' : ''}`} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>

        {
            data?.styledTitle && <div className={classes.Title} style={{marginBottom: 14}}>
                <span dangerouslySetInnerHTML={{ __html: data.styledTitle }} />
            </div>
        }

        <div className={classes.Container} style={{
            backgroundImage: `url(${data?.theme?.backgroundUrl})`,
        }}>
            <div className={classes.Hd}>
                <ImageContainer style={{ aspectRatio }} href={url} sensors={{
                    ...sensors,
                    refId: data?.refId,
                    type: '1',
                }} position={0}>
                    <LazyLoadImage alt={data?.title} src={data?.src} />
                </ImageContainer>
            </div>

            <div className={classes.Bd}>
                <ListSwiperProducts
                    items={products}
                    slidesPerView={6}
                    render={(item, index) => {
                        return <Product sensors={
                            {
                                resourcepage_title: sensors?.title,
                                resource_position: sensors?.position,
                                resource_content: data?.refId,
                                resource_type: '1',
                            }
                        } column={data?.refId} product={item} position={index} multicolorNotDisplay /> 
                    }}
                    showBgNavigation
                    moreUrl={getSensorsUrl(url, {
                        resourcepage_title: sensors?.title,
                        resource_type: '1',
                        resource_content: data?.refId,
                        resource_position: sensors?.position,
                    })}
                />

            </div>
        </div>
    </div>
}))