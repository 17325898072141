import React from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import Jiugongge from '../../../../layout/fragments/raffle/components/jiugongge'

export default props => {
    useStyles(classes)
    const { data } = props;
    return <div className={classes.Container} id={data?.id}>
        
        <Jiugongge/>
    </div>
}