import React from "react";
import { isIOS, isMacOs } from 'react-device-detect'

export default props =>{
    const { shareValue } = props;

    const messageShareEvent = () =>{
        if (isIOS || isMacOs) {
            window.location.href = `sms:&body=${shareValue}`;
        } else {
            window.location.href = `sms:?body=${shareValue}`;
        }
    }

    return <a 
            onClick={() =>messageShareEvent()} 
            className={props.className}>
        {props.children}
    </a>
}