import React, { useEffect, useState } from 'react'

import { fetchBanner } from '../../../../../../api'
import { FullSwiper } from '../../../../../pages/moduleable/components/swiper'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import ImageContainer from '../../../../../components/image/image-container'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { getCollectionUrl } from '../../../../../../utils/url'
import { IMAGE_GEEKO_LTD } from '../../../../../../constants'

import { useSelector } from 'react-redux'

import { Types } from './index'
import withObserver from '../../../../../pages/moduleable/modules/hoc/withObserver'
import { getStyleObjectFromString } from '../../../../../utils/style'


export default withObserver(props => {
	useStyles(classes)

	const pageHome = useSelector((state) => state.pageHome)

	const { data, sensors, innerRef } = props
	const { width = 1800, height = 650, style } = data
	const [banners, setBanners] = useState(data.data)

	const [swiper, setSwiper] = useState()

	const [showNavi, setShowNavi] = useState(false);

	const styles = getStyleObjectFromString(style)

	const aspectRatio = width && height ? `${width} / ${height}` : undefined

	useEffect(async () => {

		if (!data.data || data.data.length < 1) {
			const banners = await fetchBanner(pageHome?.currentPage || '1').then(data => data.result).catch(e => {
				console.error(e)
				return null
			})

			setBanners(banners)
		}

	}, [pageHome?.currentPage])

	const clickHandle = (flag) => {
		if (!!flag) {
			swiper.slidePrev(500);
		} else {
			swiper.slideNext(500);
		}
	}

	const pagination = {
		clickable: true,
		renderBullet: function (index, className) {
			return `
                <span class="${className}">${index}</span>
            `
		},
		clickableClass: classes.SwiperPagination,
		bulletActiveClass: classes.Active,
		bulletClass: classes.Pagegation
	}

	return <div ref={innerRef} className={`${!data?.full ? 'MaxWith' : ''}`} id={data?.id} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>
		<div className={classes.BannerContainer}
			onMouseEnter={() => setShowNavi(true)}
			onMouseLeave={() => setShowNavi(false)}
		>
			{
				banners?.length > 1 ? <FullSwiper key={banners} items={banners} style={{
					aspectRatio,
					backgroundColor: '#f1f1f1'
				}} options={{
					onSwiper: (s) => setSwiper(s),
					navigation: {
						prevEl: null,
						nextEl: null,
					},
					autoplay: banners?.length > 1 ? {
						delay: 5000,
						disableOnInteraction: false
					} : false,
					pagination: banners?.length > 1 ? pagination : false,
					loop: banners?.length > 1,
				}} render={(item, index) => {
					const url = item.relatePcHref || getCollectionUrl(item.id, item.name)
					return <ImageContainer href={url} title={item.name} src={item.pcImage} position={index} sensors={{
						...sensors,
						refId: item?.enName || item.name,
						type: '1',
					}}>
						{
							index === 0 ? <img alt={item.name} src={`${IMAGE_GEEKO_LTD}${item.pcImage}`} /> : <LazyLoadImage alt={item.name} src={`${IMAGE_GEEKO_LTD}${item.pcImage}`} />
						}
					</ImageContainer>
				}} /> : <ImageContainer href={banners?.[0]?.relatePcHref || getCollectionUrl(banners?.[0]?.id, banners?.[0]?.name)} title={banners?.[0]?.name} src={banners?.[0]?.pcImage} sensors={{
					...sensors,
					refId: banners?.[0]?.enName || banners?.[0]?.name,
					type: '1',
				}}>
					<img alt={banners?.[0]?.name} src={`${IMAGE_GEEKO_LTD}${banners?.[0]?.pcImage}`} />
				</ImageContainer>
			}


			{
				banners?.length > 1 && <div style={{ display: `${showNavi ? 'block' : 'none'}` }}>
					<span className={classes.Prev} onClick={() => clickHandle(true)}>
						<span>&lsaquo;</span>
					</span>
					<span className={classes.Next} onClick={() => clickHandle(false)}>
						<span>&rsaquo;</span>
					</span>
				</div>
			}

		</div>
	</div>
})
