import React, { useCallback } from 'react'
import withList from '../../../../../pages/moduleable/modules/list/hoc/withList'
import ImageContainer from '../../../../../components/image/image-container'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ListProducts from './components/list-products'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { getStyleObjectFromString } from '../../../../../utils/style'
import withObserver from '../../../../../pages/moduleable/modules/hoc/withObserver'
import { FormattedMessage } from 'react-intl'
import ViewMore from '../../../../../pages/moduleable/modules/list/components/view-more'

export default withList(withObserver(props => {
    useStyles(classes)
    const { products, data, sensors, innerRef } = props

    const styles = getStyleObjectFromString(data?.style)

    const listStyles = getStyleObjectFromString(data?.listStyle);

    const url = data?.href

    const aspectRatio = data?.width && data?.height ? `${data?.width} / ${data?.height}` : undefined

    return <div ref={innerRef} id={data?.id} className={`${classes.ListContainer} ${!data?.full ? 'MaxWith' : ''}`} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>
        {
            data?.styledTitle && <div className={classes.Title}>
                <ViewMore sensors={
                    {
                        resourcepage_title: sensors?.title,
                        resource_position: sensors?.position,
                        resource_content: data?.refId,
                        resource_type: '1',
                    }
                } href={url} dangerouslySetInnerHTML={{ __html: data.styledTitle }} />
            </div>
        }

        {
            data?.src && <div className={classes.ListImage}>
                <ImageContainer style={{ aspectRatio }} href={url} sensors={{
                    ...sensors,
                    refId: data?.refId,
                    type: '1',
                }} position={0}>
                    <LazyLoadImage alt={data?.title} src={data?.src} />
                </ImageContainer>
            </div>
        }

        <div className={`${classes.ListContainer2}`} style={{ ...listStyles }}>
            <ListProducts
                className={`${classes.Products} ${classes.RowSix}`}
                sensors={
                    {
                        resourcepage_title: sensors?.title,
                        resource_position: sensors?.position,
                        resource_content: data?.refId,
                        resource_type: '1',
                    }
                }
                column={data?.refId}
                products={products}
                count={12}
            />

            <div className={classes.ViewAll}>
                <ViewMore sensors={
                    {
                        resourcepage_title: sensors?.title,
                        resource_position: sensors?.position,
                        resource_content: data?.refId,
                        resource_type: '1',
                    }
                } className={classes.ViewMore2} href={url}><FormattedMessage id="view_all" defaultMessage="View All" />{' >'}</ViewMore>
            </div>
        </div>
    </div>
}))