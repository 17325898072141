import React, {useCallback} from 'react'
import ImageContainer from '../../../../../components/image/image-container'
import withLastMoreList from '../../../../../pages/moduleable/modules/list/hoc/withLastMoreList'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { ViewMoreProductItem } from '../../../../../desktop/pages/listing/components/item/index';
import ListSwiperProducts from './components/list-swiper-products'
import { List6Product } from '../../../listing/components/item/simple-item';
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import {getStyleObjectFromString} from '../../../../../utils/style'
import { Types } from './index'
import withObserver from '../../../../../pages/moduleable/modules/hoc/withObserver'
import { FormattedMessage } from 'react-intl';
import { getSensorsUrl } from '../../../../../utils/sensor';


export default withLastMoreList(withObserver(props => {
    useStyles(classes)
    const { products, data, sensors, innerRef } = props

    const styles = getStyleObjectFromString(data?.style)

    const listStyles = getStyleObjectFromString(data?.listStyle);

    const url = data?.href

    const aspectRatio = data?.width && data?.height ? `${data?.width} / ${data?.height}` : undefined

    const clickHandle = useCallback(e => {
		e.preventDefault()
		const href = e.currentTarget?.href
		window.location.href = getSensorsUrl(href, {
			resourcepage_title: sensors?.title,
			resource_type: '1',
			resource_content: data?.refId,
			resource_position: sensors?.position,
		})
	}, [])

    return <div ref={innerRef} id={data?.id} className={`${classes.ListContainer} ${!data?.full? 'MaxWith': ''}`}  style={{ paddingTop: `${data?.marginTop}`, ...styles }}>
        <div className={classes.List2Hd}>
            {
                data?.styledTitle && <div className={classes.Title}>
                    <a onClick={clickHandle} href={url} dangerouslySetInnerHTML={{ __html: data.styledTitle }} />
                </div>
            }

            <div className={classes.TopRightViewAll}>
                <a onClick={clickHandle} href={url}><FormattedMessage id="view_all" defaultMessage="View All" />{' >'}</a>
            </div>
        </div>
        
        {
            data?.src && <ImageContainer style={{aspectRatio}} href={url} sensors={{
                ...sensors,
                refId: data?.refId,
                type: '1',
            }}>
                <LazyLoadImage alt={data?.title} src={data?.src} />
            </ImageContainer>
        }

        <div style={{...listStyles}}>
            <ListSwiperProducts 
                sensors={{
                    ...sensors,
                    refId: data?.refId,
                    type: '1',
                }}
                items={products} 
                slidesPerView={6}
                slidesPerGroup={1}
                options={{
                    loop:false
                }}
                showBgNavigation
                className={classes.ListBd}
                render={(item, index)=>{
                    return <React.Fragment>
                        {
                            item?.id ? 
                            <List6Product 
                                sensors={
                                    {
                                        resourcepage_title: sensors?.title,
                                        resource_position: sensors?.position,
                                        resource_content: data?.refId,
                                        resource_type: '1',
                                    }
                                } 
                                column={data?.refId} 
                                product={item} 
                                position={index}
                                showRanking
                            /> : <ViewMoreProductItem 
                                    sensors={{
                                        ...sensors,
                                        refId: data?.refId,
                                        type: '1',
                                    }} column={data?.refId} 
                                    item={
                                        {
                                            href:data?.href
                                        }
                                    } 
                                    position={index} 
                                />
                        }
                    </React.Fragment>
                    
                }}
            />
        </div>
    </div>
}))