import React, { useCallback, useEffect, useRef, useState } from 'react'
import { subscribeWebPush } from '../../../../api'
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from './style.module.scss'
import Cache from '../../../../utils/cache'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { useApp, useQuery } from '../../../hooks/common'


const urlBase64ToUint8Array = base64String => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4)
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')
    const rawData = window.atob(base64)
    return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)))
}

const registerServiceWorker = () => {
    return navigator.serviceWorker.register('/sw.js').then(function (registration) {
        console.log('Service worker successfully registered.')
        return registration
    }).catch(function (err) {
        console.error('Unable to register service worker.', err)
    })
}


const subscribeUserToPush = () => {
    return registerServiceWorker().then(registration => {
        const subscribeOptions = {
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(
                'BD8xdbVAdvdLB2qG59oU5OfJfk34wNujuhhqQyq4vJWmBemXdh5-qFWhYUAM_WpY2VDOqu1tXMwC6dN9zUgwWIk='
            )
        }

        return registration.pushManager.subscribe(subscribeOptions)
    }).then(function (pushSubscription) {
        console.log('Received PushSubscription: ', JSON.stringify(pushSubscription));
        return pushSubscription
    })
}

const WebPush = props => {

    useStyles(classes)

    const global = useSelector(state => state.global)
    const query = useQuery()
    const [show, setShow] = useState(false)
    const [touched, setTouched] = useState(false)
    const isApp = useApp()

    const handleTouch = useCallback(e => {
        setTouched(true)
    }, [])

    useEffect(() => {
        window.addEventListener('touchstart', handleTouch)

        if(query?.mode === 'test'){
            console.log('+++++++++++++++++useragent+++++++++++++++++')
            console.log('Is Iphone:', global.isiPhone)
            console.log('User-Agent:', global.useragent)
            console.log('-----------------useragent-----------------')
        }

        return () => {
            window.removeEventListener('touchstart', handleTouch)
        }
    }, [])

    useEffect(() => {
        const timming = setTimeout(() => {
            const shouldShow = !!!Cache.get('notificationalert')
            if (shouldShow) {
                setShow(true)
                Cache.set('notificationalert', 1, 24 * 60 * 60)
            }
        }, 10000)

        return () => {
            clearTimeout(timming)
        }
    }, [])

    const handleSubscribe = useCallback(() => {

        try {
            Notification.requestPermission().then(permission => {
                return subscribeUserToPush().then(pushSubscription => {
                    subscribeWebPush(pushSubscription)
                    return pushSubscription
                })
            }).catch(err => {
                console.log(err)
            })

            Cache.set('notificationalert', 1, 700 * 24 * 60 * 60)

            if(window.GeekoSensors){
                window.GeekoSensors.Track('PopUp', {
                    type: 'web-push',
                    page_content:"popup",
                })

                window.GeekoSensors.Track("ELClick", {
                    clicks: 'web-push-yes'
                })
            }

            
            setShow(false)

        } catch (e) {
            console.warn(e)
        }


    }, [])


    const handleClose = useCallback(() => {
        try {
            setShow(false)
            Cache.set('notificationalert', 1, 7 * 24 * 60 * 60)

            if(window.GeekoSensors){
                window.GeekoSensors.Track('PopUp', {
                    type: 'web-push',
                    page_content:"popup",
                    popupclose:true
                })

                window.GeekoSensors.Track("ELClick", {
                    clicks: 'web-push-no'
                })
            }
        } catch (e) {
            console.warn(e)
        }
    }, [])



    return !global?.showBottomBanner && show && touched && !global.isiPhone && !global.isFacebook && !isApp ? <div className={classes.Container}>
        <div className={classes.Logo}>
            <img src={global.config?.icon} />
        </div>
        <div className={classes.BD}>
            <div className={classes.Text}>
                <FormattedMessage id="subtext" defaultMessage="We'd like to send you notifications for the latest news and updates."/>
            </div>
            <div className={classes.Buttons}>
                <button className={`${classes.Button} ${classes.No}`} onClick={handleClose}>
                    <FormattedMessage id="no_thanks" defaultMessage={'No Thanks'}/>
                </button>
                <button className={`${classes.Button} ${classes.Yes}`} onClick={handleSubscribe}>
                    <FormattedMessage id="allow" defaultMessage={'Allow'}/>
                </button>
            </div>
        </div>
    </div> : <></>
}

export {
    WebPush,
    urlBase64ToUint8Array,
    registerServiceWorker,
    subscribeUserToPush,
}