import React, { useCallback } from 'react'
import { getSensorsUrl } from '../../../../../utils/sensor'

export default props => {
    
    const { children, sensors, ...aProps } = props

    const clickHandle = e => {
        e.preventDefault()
        const href = e.currentTarget?.href

        if (window.GeekoSensors) {
            window.GeekoSensors.Track("PitPositionClick", {
                resourcepage_title: sensors?.resourcepage_title,
                resource_type: sensors?.resource_type,
                resource_content: sensors?.resource_content,
                resource_position: sensors?.resource_position,
            })
        }

        window.location.href = getSensorsUrl(href, {
            resourcepage_title: sensors?.resourcepage_title,
            resource_type: sensors?.resource_type,
            resource_content: sensors?.resource_content,
            resource_position: sensors?.resource_position,
        })
    }

    return <a {...aProps}
        data-title={sensors?.resourcepage_title}
        data-position={sensors?.resource_position}
        data-type={sensors?.resource_type}
        data-content={sensors?.resource_content}
        onClick={clickHandle}
    >
        {children}
    </a>
}