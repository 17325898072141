// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._29MonQgXmMXwirEP1CUhQG{display:flex;flex-wrap:wrap}._29MonQgXmMXwirEP1CUhQG>*{width:16.666%;padding-left:12px;padding-right:12px;padding-bottom:12px}\n", "",{"version":3,"sources":["webpack://src/client/desktop/pages/moduleable/modules/list/components/list-simple-products/style.module.scss"],"names":[],"mappings":"AAAA,yBACI,YAAa,CACb,cAAe,CAFnB,2BAIQ,aAAc,CACd,iBAAkB,CAClB,kBAAmB,CACnB,mBAAoB","sourcesContent":[".Products{\n    display: flex;\n    flex-wrap: wrap;\n    & > *{\n        width: 16.666%;\n        padding-left: 12px;\n        padding-right: 12px;\n        padding-bottom: 12px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Products": "_29MonQgXmMXwirEP1CUhQG"
};
module.exports = ___CSS_LOADER_EXPORT___;
