import React, { useState } from "react";
import classes from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { getStyleObjectFromString } from "../../../../../utils/style";
import { Swiper, SwiperSlide } from 'swiper/react'
import { useCallback } from "react";
import withCollections from "../../../../../pages/moduleable/modules/collections/withCollections";
import { Iconfont } from "../../../../../components/icon/iconfont";
import { IMAGE_GEEKO_LTD } from "../../../../../../constants";
import { getCollectionUrl } from "../../../../../../utils/url";
import ImageContainer from "../../../../../components/image/image-container";
import { Types } from "./index";


const SwiperContainer = withCollections(props =>{
    const { collections, sensors, data } = props;
    const [swiper, setSwiper] = useState(null);

    const clickHandle = useCallback((flag) =>{
        if(!!flag){
            swiper?.slidePrev(500);
        }else{
            swiper?.slideNext(500);
        }
    },[swiper])

    const getUrl = collection =>{
        return collection?.relatePcHref || getCollectionUrl(collection?.id, collection?.name)
    }
    
    return <div className={classes.Collection5SwiperContainer}>
        {
            collections?.length > 0 ? (
                <React.Fragment>
                    <Swiper
                        slidesPerView={'auto'}
                        onSwiper={(s)=>setSwiper(s)}
                        navigation={{
                            prevEl: null,
                            nextEl: null,
                        }}
                        centeredSlides={true}
                        loop={true}
                    >
                        {
                            collections.map((collection,index)=><SwiperSlide className={`swiper-no-swiping ${classes.SwiperSlice}`} key={collection?.id+index}>
                                {
                                    ({ isActive, isPrev, isNext }) =>{
                                        return <div className={`${classes.SwiperItem} ${isActive?classes.slideActiveClass:''} ${isPrev?classes.slidePrevClass:''} ${isNext?classes.slideNextClass:''}`}>
                                            <ImageContainer
                                                href={getUrl(collection)}
                                                title={collection.name} 
                                                position={index} 
                                                className={classes.LinkItem}
                                                sensors={{
                                                    ...sensors,
                                                    refId: collection.enName || collection.name,
                                                    type: '1',
                                                }}
                                            >
                                                <div className={classes.ImageContainer}>
                                                    <img src={`${IMAGE_GEEKO_LTD}${collection.pcImage}`} alt="" />
                                                </div>
                                            </ImageContainer>

                                            {
                                                !isActive &&  <div className={classes.Mask} onClick={(e)=>{
                                                        e.stopPropagation();
                                                        clickHandle(isPrev)
                                                }}></div>
                                            }
                                        </div>
                                    }
                                }
                            </SwiperSlide>)
                        }

                        <span className={classes.SlidePrevBtn} onClick={()=>clickHandle(true)}>
                            <Iconfont>&#xe693;</Iconfont>
                        </span>
                        <span className={classes.SlidNextBtn} onClick={()=>clickHandle(false)}>
                            <Iconfont>&#xe694;</Iconfont>
                        </span>
                    </Swiper>
                </React.Fragment>
            ) : (
                <div style={{display:'flex',justifyContent:'center'}}>
                    <div className={`${classes.SwiperItem} ${classes.slidePrevClass}`} style={{flexShrink: 0}}></div>
                    <div className={`${classes.SwiperItem} ${classes.slideActiveClass}`} style={{flexShrink: 0}}></div>
                    <div className={`${classes.SwiperItem} ${classes.slideNextClass}`} style={{flexShrink: 0}}></div>
                </div>
            )
        }
    </div>
});

// const TransferComponent = props =>{

//     return 
// }

export default props =>{
    useStyles(classes);
    const { data } = props;

    const styles = getStyleObjectFromString(data?.style)

    return <div id={data?.id} className={`${!data?.full? 'MaxWith': ''}`} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>
        <div className={classes.Collection5Hd}>
            {
                data?.styledTitle && <a href={data?.href} className={classes.Title1} dangerouslySetInnerHTML={{__html:data.styledTitle}}></a>
            }
            
            {
                data?.description && <p className={classes.Title2} dangerouslySetInnerHTML={{__html:data?.description}}></p>
            }
            
        </div>

        <SwiperContainer {...props} />
    </div>
}