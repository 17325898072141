// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kqqRuPdoN3YWH2pOCvYyq{display:block;text-decoration:none;color:#222}.kqqRuPdoN3YWH2pOCvYyq img{width:100%}.kqqRuPdoN3YWH2pOCvYyq img.H4FUcr9p9BiCNmT7gth7Y{display:block}.kqqRuPdoN3YWH2pOCvYyq img._2kpqJ3RqGiDgMcEIbW_y9q{display:none}.kqqRuPdoN3YWH2pOCvYyq._3FdOpnyFW0nVOdIwmgEVF img.H4FUcr9p9BiCNmT7gth7Y{display:none}.kqqRuPdoN3YWH2pOCvYyq._3FdOpnyFW0nVOdIwmgEVF img._2kpqJ3RqGiDgMcEIbW_y9q{display:block}._1AGVwJvdLsxw6-6TknZ0Xk{display:flex;flex-wrap:wrap}\n", "",{"version":3,"sources":["webpack://src/client/desktop/pages/moduleable/modules/festival/images/style.module.scss"],"names":[],"mappings":"AAAA,uBACI,aAAc,CACd,oBAAqB,CACrB,UAAW,CAHf,2BAKQ,UAAW,CALnB,iDAOY,aAAc,CAP1B,mDAUY,YAAa,CAVzB,wEAkBgB,YAAa,CAlB7B,0EAqBgB,aAAc,CACjB,yBAMT,YAAa,CACb,cAAe","sourcesContent":[".Image{\n    display: block;\n    text-decoration: none;\n    color: #222;\n    img{\n        width: 100%;\n        &.Main{\n            display: block;\n        }\n        &.Secondary{\n            display: none;\n            \n        }\n    }\n\n    &.Active{\n        img{\n            &.Main{\n                display: none;\n            }\n            &.Secondary{\n                display: block;\n            }\n        }\n    }\n}\n\n.Images{\n    display: flex;\n    flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Image": "kqqRuPdoN3YWH2pOCvYyq",
	"Main": "H4FUcr9p9BiCNmT7gth7Y",
	"Secondary": "_2kpqJ3RqGiDgMcEIbW_y9q",
	"Active": "_3FdOpnyFW0nVOdIwmgEVF",
	"Images": "_1AGVwJvdLsxw6-6TknZ0Xk"
};
module.exports = ___CSS_LOADER_EXPORT___;
