import React, { useState, useCallback, useRef, useEffect, useMemo } from "react";
import cls from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import DisplayTransition from "../../../../../../components/transitions/display-transition";
import Mask from "../../../../../../components/mask";
import ReactPlayer from "react-player/lazy";
import { SimpleProducts, EmptyProducts } from "../../../../../listing/components/list";
import ReactDOM from "react-dom";
import { fetchCollectionList, fetchCategoryList } from "../../../../../../../../api";
import INTERFACE_TYPE from "../../../../../../../pages/moduleable/modules/list/hoc/eumn";
import { Iconfont } from "../../../../../../../components/icon/iconfont";
import { FormattedMessage } from "react-intl";

const PanelRight = props =>{
    const { imageData } = props;
    const { relatedId, productCount, interface: fetchType } = imageData;
    const [products, setProducts] = useState([]);

    useEffect(async () =>{
        if(relatedId){
            let result;
            switch (fetchType) {
                case INTERFACE_TYPE.COLLECTION:
                    result = await fetchCollectionList({ collectionId: relatedId }, { skip: 0, limit: productCount || 12 }).then(data => data.result).catch(e => {
                        console.error(e)
                        return []
                    })
                    break
                case INTERFACE_TYPE.CATEGORY:
                    result = await fetchCategoryList({ categoryId: relatedId }, { skip: 0, limit: productCount || 12 }).then(data => data.result).catch(e => {
                        console.error(e)
                        return []
                    })
                    break
                default:
                    result = []
                    break
            }
            setProducts(result?.filter(p=> !p.isCollection && !p.isSmartCard))
        }
    }, [relatedId]);

    const productsLength = useMemo(() =>{
        return products?.length || "x";
    }, [products]);

    return <div className={cls.PanelRight}>
        <div className={cls.Title}><FormattedMessage id="more_products" defaultMessage="More Products" />({productsLength})</div>

        <div className={cls.ProductsScroll}>
            {
                products?.length > 0 ? <React.Fragment>
                    <SimpleProducts 
                        products={products} 
                        className={cls.Products} 
                    />
                </React.Fragment> : <React.Fragment>
                    <EmptyProducts className={cls.Products}  />
                </React.Fragment>
            }
        </div>
    </div>
}

const PanelLeft = props =>{
    const { imageData, show } = props;
    const { youtubeId } = imageData;

    const [muted, setMuted] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [ready, setReady] = useState(false);
    const player = useRef();

    useEffect(() =>{
        if(!show){
            setPlaying(false)
        }
    }, [show]);

    const handleStart = useCallback(() => {
        // setMuted(false)
    }, []);

    const handleReady = useCallback(() => {
        setReady(true)
    }, []);

    const maskClickHandle = event => {
        setPlaying(!playing)
    }
    
    return <div className={cls.PanelLeft}>
        <div className={cls.VideoMask} onClick={maskClickHandle}></div>

        <ReactPlayer
            ref={player}
            playing={playing}
            loop
            muted={muted}
            onStart={handleStart}
            onReady={handleReady}
            width='100%' 
            height='100%' 
            url={`https://www.youtube.com/watch?v=${youtubeId}`} 
        />
    </div>
}

const PopupVideo = props =>{
    const { imageData, show, onClose } = props;

    return <div className={cls.PlayerPanelContainer}>
        <Iconfont className={cls.CloseIcon} onClick={onClose}>&#xe69a;</Iconfont>

        <PanelLeft imageData={imageData} show={show} />

        <PanelRight imageData={imageData} />
    </div>
}

const WrappedEditor = props => {
    useStyles(cls);
    const { show, onClose } = props;

    return <React.Fragment>
        <DisplayTransition in={show} className={cls.PopupVideoContainer}>
            <div>
                <PopupVideo {...props} />
            </div>
        </DisplayTransition>
        {show && <Mask style={{
            zIndex: 10
        }} onClick={onClose} />}
    </React.Fragment>
}


export default props => {

    if (typeof window === 'undefined')
        return null

    return ReactDOM.createPortal(
        <WrappedEditor {...props} />,
        document.getElementById('root')
    )
}