import React from 'react'
import { getStyleObjectFromString } from '../../../../../utils/style'
import SiginAnniversary from '../../components/sigin-anniversary'

export default props => {
    const {data, sensors, innerRef} = props
    const styles = getStyleObjectFromString(data?.style)
    return <div ref={innerRef} 
                id={data?.id} 
                data-position={`${sensors?.position || 1}-${ 1}`}
                data-type={sensors?.type}
                data-content={sensors?.refId}
                data-title={sensors?.title}
                style={{
                    paddingTop: `${data?.marginTop}`, 
                    ...styles
                }}>
        <SiginAnniversary backgroundImage={data?.backgroundUrl} backgroundColor={data?.backgroundColor} backgroundImageBottom={data?.backgroundImageBottom}/>
    </div>
}