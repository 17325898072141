// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1WhJN0iWz2oUAFsn0yMbD{display:block;text-decoration:none;color:#222;position:relative}._1WhJN0iWz2oUAFsn0yMbD img{position:absolute;width:100%;height:100%;z-index:1;top:0;left:0}._1WhJN0iWz2oUAFsn0yMbD img.p4iruiHtCpHkWHIt8h7bj{opacity:0}\n", "",{"version":3,"sources":["webpack://src/client/desktop/pages/moduleable/modules/video/style.module.scss"],"names":[],"mappings":"AAAA,wBACI,aAAc,CACd,oBAAqB,CACrB,UAAW,CACX,iBAAkB,CAJtB,4BAMQ,iBAAkB,CAClB,UAAW,CACX,WAAY,CACZ,SAAU,CACV,KAAM,CACN,MAAO,CAXf,kDAaY,SAAU","sourcesContent":[".VideoWrapper{\n    display: block;\n    text-decoration: none;\n    color: #222;\n    position: relative;\n    img{\n        position: absolute;\n        width: 100%;\n        height: 100%;\n        z-index: 1;\n        top: 0;\n        left: 0;\n        &.Entered{\n            opacity: 0;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"VideoWrapper": "_1WhJN0iWz2oUAFsn0yMbD",
	"Entered": "p4iruiHtCpHkWHIt8h7bj"
};
module.exports = ___CSS_LOADER_EXPORT___;
