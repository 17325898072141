import React from "react";

export default props =>{
    const { shareValue } = props;

    const shareClickEvent = () =>{
        window.location.href = `https://api.whatsapp.com/send?text=${shareValue}`;
    }

    return <a 
            onClick={() =>shareClickEvent()} 
            className={props.className}
        >
        {props.children}
    </a>
}