import React from 'react'
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from './style.module.scss'
import { FormattedMessage } from "react-intl";
import ReactDOM from 'react-dom'

import { IMAGE_GEEKO_LTD } from '../../../../../../../../constants';
import { Iconfont } from '../../../../../../../components/icon/iconfont';
import CountDown from '../../../../../../../components/count-down';
import Mask from '../../../../../../components/mask';


const GiftMask = (props) => {
    useStyles(classes)

    const {data, onClose} = props

    const cashCoupon = data?.cashCoupon

    // console.log(data)
    return <>
        <div className={classes.LotteryAlertModal}>
            <Iconfont onClick={onClose} className={classes.CloseModal}>&#xe69a;</Iconfont>
            {
                data?.fontAboveImg && data?.fontAboveImg?.label &&
                <div className={classes.Font1} style={{ ...data?.fontAboveImg?.style }}>
                    {data?.fontAboveImg?.label}
                </div>
            }
            <React.Fragment>
                {
                    data?.imageUrl && <div className={classes.LotteryAlertModalImage}>
                        <img 
                            src={`${IMAGE_GEEKO_LTD}${data?.imageUrl}`} 
                            alt="luckImage" 
                            // style={{width:`${data?.imageWidth?data.imageWidth:'60%'}`}} 
                        />
                    </div>
                }
            </React.Fragment>

            {
                data?.giftImg && data?.giftImg?.url &&
                <div className={classes.LotteryAlertModalImage}>
                    <img 
                        src={`${IMAGE_GEEKO_LTD}${data?.giftImg?.url}`} 
                        alt="luckImage" 
                        // style={{width:`${data?.imageWidth?data.imageWidth:'60%'}`}} 
                    />
                </div>
            }

            <div style={{...data?.customerMadeStyle, display:'flex', alignItems:'center', flexDirection:'column'}} >
                {
                    data?.font1 && data?.font1?.label && <div className={classes.Font1} style={{ ...data?.font1?.style }}>
                        {data?.font1?.label}
                    </div>
                }

                {
                    data?.font2 && data?.font2?.label && <div className={classes.Font1} style={{ ...data?.font2?.style }}>
                        <span dangerouslySetInnerHTML={{__html:data?.font2?.label}}></span>
                    </div>
                }

                {
                    data?.showTimes && <div className={classes.ExpireIn}>
                        <p><FormattedMessage id="expire_in" defaultMessage="Expire In" />:</p>
                        <CountDown 
                            offset={data?.showTimes} 
                            showLabel
                            showHour 
                            numberStyle={{backgroundColor: '#222',color:'#ffffff',padding:'2px 2px 2px 4px',fontSize: '1vw',fontFamily: 'Roboto-Medium'}} 
                            labelStyle={{backgroundColor: '#222',color:'#ffffff',padding:'2px 4px 2px 0px',textTransform: 'uppercase',fontSize: '1vw',fontFamily: 'Roboto-Medium'}} 
                            dotStyle={{color:'#222',margin:'0 3px'}} 
                        />
                    </div>
                }

                {
                    data?.tip && data?.tip?.label &&
                    <div className={classes.TipFont} style={{ ...data?.tip?.style }}>
                        <span dangerouslySetInnerHTML={{__html:data?.tip?.label}}></span>
                    </div>
                }

                {
                    data?.rulesContent && data?.rulesContent?.content &&
                    <div className={classes.TipFont} style={{ ...data?.rulesContent?.style }}>
                        <span dangerouslySetInnerHTML={{__html:data?.rulesContent?.content}}></span>
                    </div>
                }

                <div className={classes.ButtonContainer}>
                    {
                        data?.btn1 && <button onClick={data?.btn1?.callback} className={classes.Btn1}>{data?.btn1?.label}</button>
                    }

                    {
                        data?.btn2 && <button onClick={data?.btn2?.callback} className={classes.Btn2}>{data?.btn2?.label}</button>
                    }
                </div>
            </div>

        </div>
    </>
}

export default props => {    
    return <>
    {
        ReactDOM.createPortal(<React.Fragment>
            <GiftMask {...props}/>
            <Mask onClick={props.onClose} style={{zIndex:22}}/>
        </React.Fragment>,
        typeof document !== 'undefined' ? document.getElementById('root') : null)
    }
    
    </>
}