import React from "react";
import classes from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { Iconfont } from "../../../../../components/icon/iconfont";
import { IMAGE_GEEKO_LTD } from "../../../../../../constants";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { useEffect } from "react";
import { getShowingList } from "../../../../../../api";
import { useState } from "react";
import { getStyleObjectFromString } from "../../../../../utils/style";
import { FormattedMessage } from "react-intl";
import CartIcon from "../../../listing/components/cart/cart-icon";
import withFirst from "../../../../../pages/details/hoc/withFirst";

const SOCIAL_ICONS = id => {
	switch(id){
		case 'facebook':
			return <Iconfont style={{fontSize:32}}>&#xe79c;</Iconfont>
		case 'ins':
			return <Iconfont>&#xe79e;</Iconfont>
		case 'tiktok':
			return <Iconfont>&#xe79b;</Iconfont>
		case 'youtube':
			return <Iconfont style={{fontSize:26}}>&#xe79d;</Iconfont>
		default:
			return ''		
	}
}

const GalsItem = props =>{
    const { show, position, sensors, data } = props;
    const { products } = show;
    const product = products?.[0];

    return <a className={classes.GalsListLink}>
        <div className={classes.GalsListContent}>
            <div className={classes.Image}>
                <img src={`${IMAGE_GEEKO_LTD}/showing/medium/${show?.id}`} alt={data?.refId} />
            </div>

            <div className={classes.Shop}>
                <Iconfont className={classes.Icon}>&#xe79e;</Iconfont>

                <div>
                    <CartIcon
                        render={() => <span className={classes.AddToCart}><FormattedMessage id="shop_the_look" defaultMessage="Shop the Look" /></span>}
                        sensors={
                            {
                                ...sensors,
                                layerName: product?.layerName,
                                trackingRecords: product?.trackingRecords,
                                dataSource: product?.dataSource,
                                geekoExperimentId: product?.geekoExperimentId,
                                geekoRequsestId: product?.geekoRequsestId,
                                aliRequestId: product?.aliRequestId,
                                aliExperimentId: product?.aliExperimentId,
                                position: position + 1
                            }
                        } 
                        productId={product.id} 
                        color={product?.variants?.[0]?.color} 
                    />
                </div>
            </div>
        </div>
    </a>
}

const GalsList = withFirst(props =>{
    const { innerRef, showing, sensors } = props;
    
    return <div className={classes.GalsList} ref={innerRef}>
        {
            showing?.length > 0 ? (
                showing.slice(0,5).map((item, index)=>{
                    return <div className={classes.GalsListItem} key={index}>
                        <GalsItem show={item} position={index} sensors={sensors} {...props} />
                    </div>
                })
            ) : (
                [1, 1, 1, 1, 1].map((item, index) =>{
                    return <div className={classes.GalsListItem} key={index}>
                        <a className={classes.GalsListLink}>
                            <div className={classes.GalsListContent}>
                                <div className={classes.Image}>
                                    <img alt="empty" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" />
                                </div>
                            </div>
                        </a>
                    </div>
                })
            )
        }
    </div>
})

const TransferComponent = props =>{
    const [showing, setShowing] = useState(null);

    const initData = async () =>{
        let response = await getShowingList();
        if(response.code === 200){
            setShowing(response?.result)
        }
    }
    
    return <GalsList {...props} initLoad={initData} showing={showing} />
}

export default props =>{
    useStyles(classes);
    const { data } = props;
    const global = useSelector(state=>state.global);
    const { config } = global;
    
    const styles = getStyleObjectFromString(data?.style)

    const socials = useMemo(()=>{
        return config?.socials?.filter(item=>item?.id != "pintrest");
    },[config?.socials])

    return <div className={`${!data?.full? 'MaxWith': ''}`} id={data?.id} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>
        <div className={classes.GalsContainer}>
            <div className={classes.Title}>
                <a className={classes.TitleLink} href={data.src}>
                    <span dangerouslySetInnerHTML={{__html:data.styledTitle}}></span>
                </a>
            </div>

            <div className={classes.Socials}>
                {
                    socials?.map((social, index)=>{
                        return <a className={classes.Social} href={social?.url} key={index}>
                            {SOCIAL_ICONS(social.id)}
                        </a>
                    })
                }
            </div>

            <TransferComponent {...props} />
        </div>
    </div>
}