import React, {useCallback} from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import withSource from '../../../../../hocs/source-scoll'
import { getSensorsUrl } from '../../../../../utils/sensor'


const Image = withSource(props => {
	const { image, sensors, innerRef, position, showVideoEvent } = props
	const { percent, width, height, title, href } = image
	const aspectRatio = width && height ? `${width} / ${height}` : undefined

	let ratio
	if(width && height){
		ratio = `${Math.floor(height*100/width)}%`
	}

	const clickHandle = useCallback(e => {
		e.preventDefault()
		if (!!showVideoEvent){
			showVideoEvent(image);
		} else {
			const href = e.currentTarget?.href
			window.location.href = getSensorsUrl(href, {
				resourcepage_title: sensors?.title,
				resource_type: sensors?.type,
				resource_content: image.refId,
				resource_position: `${sensors?.position || 1}-${position + 1}`,
			})
		}
		
	}, [image])

	return <a
		ref={innerRef}
		data-position={`${sensors?.position || 1}-${position + 1}`}
		data-type={sensors?.type}
		data-content={image.refId || image.title}
		data-title={sensors?.title}
		style={{
			width: isNaN(percent) ? percent : `${Number(percent) * 100}%`
		}}
		onClick={clickHandle}
		href={href} title={title}>
			<div className={`${classes.Image} ${!ratio?classes.Static:''}`} style={{
				aspectRatio,
				paddingTop: ratio,
			}}>
				{
					props?.render(image)
				}
			</div>
	</a>
})

export default props => {
	useStyles(classes)
	const { images, title, sensors, special, direction, showVideoEvent } = props
	return <div className={classes.Container}>
		{
			title && <div className={classes.Title}>
				{
					title
				}
			</div>
		}

		<div className={`${classes.Images} ${special? classes.Special:''} ${direction === 'rtl'? classes.Rtl: ''}`}>
			{
				images?.map((image, index) => {
					const { refId, id } = image
					return <Image position={index} render={
						image => props?.render(image, index)
					} sensors={sensors} key={refId || id || index} image={image} showVideoEvent={showVideoEvent} />
				})
			}
		</div>

	</div>
}