import React, { useState } from 'react'
import withRec from '../../../../../pages/moduleable/modules/list/hoc/withDymaticGroup'
import Products from './components/list-products'
import { FormattedMessage } from 'react-intl'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { Types } from './index'
import withObserver from '../../../../../pages/moduleable/modules/hoc/withObserver'
import withSwiper from './hoc/swiper'
import { getStyleObjectFromString } from '../../../../../utils/style'
import withSource from '../../../../../hocs/source-scoll'
import { useLocation } from 'react-router-dom'

const Tab = withSource(props => {
    useStyles(classes)
    const {selectedItem, innerRef, sensors, item, handleClick, position} = props
    return <span
        ref={innerRef}
        data-position={`${sensors?.position || 1}-${(position || 0) + 1}`}
        data-type={sensors?.type}
        data-content={item?.refId || item.title || item.name}
        data-title={sensors?.title}
        onClick={() => { handleClick(item, position) }} className={`${classes.Tab} ${selectedItem?.id === item?.id ? classes.Selected : ''}`}>{item.title || item.name}</span>
})


export const Tabs = withSwiper(props => {
    useStyles(classes)
    const { items, tabClick } = props
    return <div className={classes.Tabs2}>
        {
            items?.map((item, index) => <Tab {...props} item={item} position={index}/>)
        }
    </div>
})

export default withRec(withObserver(props => {

    useStyles(classes)


    const { loading, changeHandle, loadHandle, selectedMenu, menus, data, originMenus, sensors, abTest, innerRef } = props

    const styles = getStyleObjectFromString(data?.style)
    const [position, setPosition] = useState(1)
    const location = useLocation()
    const isFestival = location.pathname === '/i/black-friday'


    return <div ref={innerRef} id={data?.id} className={`${classes.RecContainer} ${!data?.full? 'MaxWith': ''}`} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>

        {
            (data?.styledTitle || data?.title) && <div className={classes.Title}>
                <span href={data?.href} dangerouslySetInnerHTML={{ __html: (data?.styledTitle || data?.title) }} />
            </div>
        }


        {
            originMenus && originMenus.length > 1 && <Tabs sensors={{
                ...sensors,
                type: '1',
            }} items={originMenus} onTab={
                (item, index) => {
                    changeHandle(item.id)
                    setPosition(index)
                }
            } />
        }

        

        <div>
            <div className={classes.RecProducts}>
                {
                    <Products festival={isFestival} sensors={
                        {
                            resourcepage_title: sensors?.title,
                            resource_position: `${sensors?.position}-${position}`,
                            resource_content: selectedMenu?.refId || selectedMenu?.id,
                            resource_type: '1',
                        }
                    } column={selectedMenu?.id} products={selectedMenu?.data?.products} />
                }
            </div>

            

            {
                selectedMenu?.data?.finished && <div className={classes.Finished}>
                    <FormattedMessage id="finished" defaultMessage="Finished" />
                </div>
            }

            {
                loading && !selectedMenu?.data?.finished && <div className={classes.Loading}>
                    <FormattedMessage id="loading" defaultMessage="Loading" />
                </div>
            }

            {
                !loading && !selectedMenu?.data?.finished && <div className={classes.ViewAll} onClick={() => {
                    loadHandle(selectedMenu?.id)
                }}>
                    <span className={classes.ViewMore}><FormattedMessage id="view_more" defaultMessage="View More" />{' >'}</span>
                </div>
            }

        </div>

    </div>
}))