
import React, { useState } from "react";
import classes from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { useIntl, FormattedMessage } from "react-intl";
import deeplink from "../../../../../../utils/deeplink";
import { subscribe } from "../../../../../../api";
import { Iconfont } from "../../../../../components/icon/iconfont";
import { BigButton } from "../../../../../components/button";
import Cache from "../../../../../../utils/cache";
import { BlackMask } from "../../../../../components/mask";
import { action_fetch_current_user } from "../../../../../../store/actions/global";
import { useDispatch } from "react-redux";

const SuccessModal = props =>{
    const { onClose } = props;

    return <React.Fragment>
        <div className={classes.SuccessModal}>
            <p className={classes.Content}>
                <FormattedMessage id="thanks_subscribe" defaultMessage="Thanks for your subscribing" />
                <FormattedMessage id="thanks_enjoy" defaultMessage="Please check your email for your username and password." />
            </p>

            <button className={classes.CloseBtn} onClick={onClose}><FormattedMessage id="confirm" defaultMessage="Confirm" /></button>
        </div>

        <BlackMask onClick={onClose} />
    </React.Fragment>
}

const AlertSubscribe = props => {
    useStyles(classes);
    const { message } = props;
    const { backgroundImage, successImage, configs } = message;

    const intl = useIntl()
    const [email, setEmail] = useState()
    const [loading, setLoading] = useState(false);
    const [showSubscrive,setShowSubscribe] = useState(false);
    const [successShow, setSuccessShow] = useState(false);
    const [showModal,setShowModal] = useState(false);
    const dispatch = useDispatch();

    const handleSub = async evt => {
        evt.preventDefault()
        if (!email) return
        setLoading(true)

        const response = await subscribe(email)

        if (response?.code === 200) {
            await dispatch(action_fetch_current_user());
            setLoading(false)
            setSuccessShow(true);
            setShowModal(true);
            Cache.set("customerEmail", email, (365 * 24 * 60 * 60) * 1000);

            try {

                if (window.GeekoSensors) {
                    window.GeekoSensors.Track('Register', {
                        register_scenes: '订阅',
                        register_method: 'email',
                        is_success: true
                    })

                    window.GeekoSensors.Track('PopUp', {
                        type: '首页订阅',
                        page_content:"popup",
                        is_subsribe: true,
                    })
                }

                if (window.fbq) {
                    window.fbq('track', "CompleteRegistration")
                }

            } catch (e) {
                console.warn(e)
            }
        } else {
            if(response?.code === 400){
                alert(intl.formatMessage({id: 'has_subscribe_to', defaultMessage:'The Email has been used by others. Please specify another Email.'}));
                setLoading(false)
            }else{
                alert(response?.result || response)
                setLoading(false)
            }
        }

    }

    return <React.Fragment>
        <div className={`${classes.AlertSubscribe} ${showSubscrive?classes.ToogleActive:""}`}>
            <div className={classes.LeftContainer} style={{backgroundColor:configs?.btnBackColor}} onClick={()=>setShowSubscribe(state=>!state)}>
                <Iconfont className={`${classes.Arrows} ${showSubscrive?classes.Active:""}`}>&#xe728;</Iconfont>

                <div className={classes.DiscountMessage}>
                    { message?.title }
                </div>
            </div>

            <div className={classes.RightContainer} style={{backgroundImage:`url(${successShow?successImage?.url:backgroundImage?.url})`}}>
                {
                    !successShow ? (
                        <div className={classes.SubmitContainer}>
                            <form action="#" className={classes.SubscribeArea} onSubmit={handleSub}>
                                <input type='email' value={email} placeholder={intl.formatMessage({id: 'enter_email_address', defaultMessage:'Enter you email address'})} onChange={evt => { setEmail(evt.target.value) }} required />
                                
                                <BigButton style={{backgroundColor:configs?.btnBackColor || '#000',color:configs?.btnFontColor || '#fff'}} className={classes.SubBtn} loading={loading}><FormattedMessage id="get_offer" defaultMessage="Get offer" /></BigButton>
                            </form>
                        </div>
                    ) : (
                        <a className={classes.SuccessLink} href={deeplink(message?.deepLink, "pc")}></a>
                    )
                }
            </div>
        </div>

        {
            showModal && <SuccessModal onClose={()=>setShowModal(false)} />
        }
    </React.Fragment>
    
}

export default AlertSubscribe;