import React, { useState, useEffect } from "react";
import classes from "./style.module.scss";
import ReactDOM from 'react-dom'
import useStyles from "isomorphic-style-loader/useStyles";
import { useSelector } from "react-redux";
import { useQuery } from "../../../../hooks/common";
import AlertSubscribe from "./subscribe";
import { fetchHomePagePopup, fetchPinterestAlert } from "../../../../../api";
import Cache from "../../../../../utils/cache";
import { PinterestDiscount } from "./discount";
import Mask from "../../../components/mask";

const IndexAlert = props => {
    useStyles(classes)
    const { message } = props;

    return <>
        {/* type;//1： 订阅 ， 2：普通弹出 */}
        {
            message?.type === "1" && <AlertSubscribe {...props} />
        }

        {/* {
            message?.type === "2" && <AlertDiscount {...props} />
        } */}
    </>
}

export const PinterestAlert = props => {
    const [message, setMessage] = useState()
    const [showAlert, setShowAlert] = useState(false)
    const ui = useSelector(state => state.ui)
    useStyles(classes)

    const handleClose = () => {
        setShowAlert(false)
        Cache.set('pinterest-alert', true)
        if(window.GeekoSensors){
            window.GeekoSensors.Track('CloseCoupon', {
                clicks: 'middle-coupon-1'
            })
        }
    }

    useEffect(async () => {
        //  请求图片
        const storedIndexAlert = Cache.get('pinterest-alert')
        if(!storedIndexAlert){
            const res = await fetchPinterestAlert()
            if(res?.code == 200){
                setMessage(res?.result? {...res?.result, serverTime:res?.serverTime}:null)
                setShowAlert(true)
                if(window.GeekoSensors){
                    window.GeekoSensors.Track('ELExpose', {
                        clicks: 'middle-coupon'
                    })
                }
            }
        }
    }, [])

    return <React.Fragment>
        {
            message && showAlert && !ui.showLottery && ReactDOM.createPortal(<React.Fragment>
                <PinterestDiscount {...props} handleClose={handleClose} message={message}/>
                <Mask onClick={handleClose} />
            </React.Fragment>, typeof document !== 'undefined' ? document.getElementById('root') : null)
        }
    </React.Fragment>
}

const AlertPopup = props =>{
    useStyles(classes);
    const [message, setMessage] = useState()
    const query = useQuery();
    const ui = useSelector(state => state.ui)
    const global = useSelector(state=>state.global);

    useEffect(async () => {
        if(!query?.show_lottery && !!!(global?.user?.id)){
            const message = await fetchHomePagePopup().then(data => data?.result?{...data?.result,serverTime:data?.serverTime}:null).catch(e => {
                console.error(e)
                return null
            })
            // let result = {
            //     "type": "2",
            //     "backgroundImage": {
            //         "url": "https://image.geeko.ltd/chicme/20220825052204231900.jpg",
            //         "width": 660.0,
            //         "height": 667.0,
            //         "locale": "en_US"
            //     },
            //     "deepLink": {
            //         "type": 16,
            //         "params": ["/landing-page/chicme-7th-anniv-sale"]
            //     },
            //     "endDate": 1661932800000,
            //     "serverTime": 1661486061730,
            //     "style": {
            //         "timeDownBackgroudColor": "#222222",
            //         "timeDownFontColor": "#ffffff"
            //     }
            // };

            // let result2 = {
            //     "type": "1",
            //     "title": "Get 10% OFF",
            //     "backgroundImage": {
            //         "url": "https://image.geeko.ltd/chicme/20220809063241276879.jpg",
            //         "width": 800.0,
            //         "height": 383.0,
            //         "locale": "en_US"
            //     },
            //     "successImage": {
            //         "url": "https://image.geeko.ltd/chicme/20220809065813666103.jpg",
            //         "width": 800.0,
            //         "height": 383.0,
            //         "locale": "en_US"
            //     },
            //     "deepLink": {
            //         "type": 16,
            //         "params": ["/"]
            //     },
            //     "configs": {
            //         "btnFontColor": "#ffffff",
            //         "btnBackColor": "#fa7a5c",
            //         "closeIconColor": "#aba9a8"
            //     }
            // }

            setMessage(message)
        }
    }, [])


    return <React.Fragment>
        {
            message && !ui.showLottery && ReactDOM.createPortal(<React.Fragment>
                <IndexAlert {...props} message={message} />
            </React.Fragment>, typeof document !== 'undefined' ? document.getElementById('root') : null)
        }
    </React.Fragment>
}

export default AlertPopup;