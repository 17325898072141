import useStyles from 'isomorphic-style-loader/useStyles'
import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import classes from "./style.module.scss"
import { FormattedMessage, useIntl } from 'react-intl'
import { subscribe } from '../../../../../api'
import Cache from '../../../../../utils/cache'
import { BigButton } from '../../../components/button'
import { IMAGE_GEEKO_LTD } from '../../../../../constants'

const PolicyAcc = props =>{

    return <div className={classes.Policy}>
        <div className={classes.Title}>{props?.title}</div>

        <ul className={classes.PolicyList}>
            {props?.children}
        </ul>
    </div>
}

const SOCIAL_ICONS = id => {
	switch(id){
		case 'facebook':
			return <span style={{backgroundImage:`url(https://image.geeko.ltd/site/pc/footer-facebook.png)`}} title={id}></span>
		case 'pintrest':
			return <span style={{backgroundImage:`url(https://image.geeko.ltd/site/pc/footer-pinterest.png)`}} title={id}></span>
		case 'ins':
			return <span style={{backgroundImage:`url(https://image.geeko.ltd/site/pc/footer-ins.png)`}} title={id}></span>
		case 'tiktok':
			return <span style={{backgroundImage:`url(https://image.geeko.ltd/site/pc/footer-tiktok.png)`}} title={id}></span>
		case 'youtube':
			return <span style={{backgroundImage:`url(https://image.geeko.ltd/site/pc/footer-youtube.png)`,marginBottom:"-5px"}} title={id}></span>
		default:
			return ''		
	}
}

const Social = props => {
	useStyles(classes)
	const {social} = props
	return <a className={`${classes.Social}`} href={social.url} title={social.id}>
		{SOCIAL_ICONS(social.id)}
	</a>
}

const SocialContact = props =>{
    const global = useSelector(state=>state.global);
    const { config } = global;

    return <div className={classes.SocialContact}>
        {
            config?.socials?.length > 0 && <div className={classes.Socials}>
                <div className={classes.Title}><FormattedMessage id="follow_us" defaultMessage="FOLLOW US" /></div>

                <div className={classes.SocialsList}>
                    {
                        config.socials.map(social => <Social key={social.id} social={social}/>)
                    }
                </div>
            </div>
        }

        <div className={classes.Download}>
            <div className={classes.Title}>APP</div>

            <div className={classes.AppIcon}>
                <a className={classes.Item} href={`https://play.google.com/store/apps/details?id=${config?.app.android.id}`}>
                    <span className={classes.FirstIcon} style={{backgroundImage:`url(https://image.geeko.ltd/site/pc/footer-andr.png)`}}></span>
                </a>
                <a href={`https://itunes.apple.com/us/app/chic-me-shopping-sharing-saving/id${config?.app.ios.id}?l=zh&ls=1&mt=8`} className={classes.Item}>
                    <span style={{backgroundImage:`url(https://image.geeko.ltd/site/pc/footer-ios.png)`}}></span>
                </a>
            </div>
        </div>
    </div>
}

const SubscribeArea = props => {

	useStyles(classes)

	const intl = useIntl();

	const [email, setEmail] = useState()

	const [loading, setLoading] = useState(false)

	const global = useSelector((state) => state.global)
	const { config } = global



	const submitEmail = (evt) => {
		evt.preventDefault()
		setLoading(true)
		subscribe(email).then(data => {
			alert(`${intl.formatMessage({id: 'thanks_subscribe'})} \n ${intl.formatMessage({id: 'thanks_enjoy'})}`);
			setLoading(false)
			Cache.set("customerEmail",email,(365*24*60*60)*1000);
		}).catch(data => {
			alert(data.result || data)
			setLoading(false)
		})
	}


	return <div className={classes.SubscriptionContainer}>
		<div className={classes.SubscribeTitle}><FormattedMessage id="subscription" defaultMessage="Subscription"/></div>
		<form onSubmit={submitEmail} action="/">
			<div className={classes.SubscribeInput}>
                <BigButton type="submit" loading={loading} className={classes.Button}><FormattedMessage id="subscribe" defaultMessage="Subscribe"/></BigButton>

				<div className={classes.Input}>
					<input placeholder={intl.formatMessage({id: 'email', defaultMessage:'Email'})} required value={email} type="email" onChange={evt => {setEmail(evt.target.value)}}/>
				</div>
			</div>
		</form>
		
		<div className={classes.FootDesc}>
			<input type="checkbox" checked/>
			<FormattedMessage id="smufcme" values={{ brand: config?.name }} defaultMessage="Sign me up for {brand} emails (you can unsubscribe at any time)."/>
		</div>
	</div>
}

export default props => {
    useStyles(classes);
    const global = useSelector(state=>state.global);
    const { config, country, payImageMessages } = global;

    const payImage = useMemo(() =>{
		if(country && payImageMessages?.length > 0){
			let data = payImageMessages.find(item =>item?.lang === country);
            if(data?.imageUrl) return data?.imageUrl
            let defaultData = payImageMessages.find(item =>item?.lang === 'other');
			return defaultData?.imageUrl || `${IMAGE_GEEKO_LTD}/chicme/20211130/1.png`;
		}
        return `${IMAGE_GEEKO_LTD}/chicme/20211130/1.png`;
	}, [country, payImageMessages]);

    return <footer className={classes.Footer}>
        <div className={classes.FooterContainer}>
            <div className={classes.Left}>
                <div className={classes.PolicyContainer}>
                    <PolicyAcc title={<FormattedMessage id="c_info" defaultMessage="{brand} Info" values={{ brand: config?.name }} />}>
                        <React.Fragment>
                            <li className={classes.PolicyItem}>
                                <a href="/policy/about-us"><FormattedMessage id="about_us" defaultMessage="About Us" /></a>
                            </li>

                            {/* <li className={classes.PolicyItem}>
                                <a href="/policy/affiliate-policy"><FormattedMessage id="affiliate" defaultMessage="Affiliate" /></a>
                            </li> */}

                            <li className={classes.PolicyItem}>
                                <a href="/policy/blogger-program"><FormattedMessage id="blogger_program" defaultMessage="Blogger Program" /></a>
                            </li>
                        </React.Fragment>
                    </PolicyAcc>

                    <PolicyAcc title={<FormattedMessage id="help_support" defaultMessage="Help &amp; Support" />}>
                        <React.Fragment>
                            <li className={classes.PolicyItem}>
                                <a href="/policy/shipping-policy"><FormattedMessage id="shipping_info" defaultMessage="Shipping Info" /></a>
                            </li>

                            <li className={classes.PolicyItem}>
                                <a href="/policy/faq"> <FormattedMessage id="faq" defaultMessage="FAQ" /></a>
                            </li>

                            <li className={classes.PolicyItem}>
                                <a href="/policy/order-tracking"><FormattedMessage id="order_tracking" defaultMessage="Order Tracking" /></a>
                            </li>

                            <li className={classes.PolicyItem}>
                                <a href="/policy/coupon-credits"><FormattedMessage id="coupons" defaultMessage="Coupons" /></a>
                            </li>

                            <li className={classes.PolicyItem}>
                                <a href="/policy/bonus-point"><FormattedMessage id="bonus_points" defaultMessage="Bonus Points" /></a>
                            </li>
                        </React.Fragment>
                    </PolicyAcc>

                    <PolicyAcc title={<FormattedMessage id="customer_care" defaultMessage="Customer Care" />}>
                        <React.Fragment>
                            <li className={classes.PolicyItem}>
                                <a href="/me/m/support"><FormattedMessage id="support" defaultMessage="Support" /></a>
                            </li>

                            <li className={classes.PolicyItem}>
                                <a href="/policy/return-policy"><FormattedMessage id="return_policy" defaultMessage="Return Policy" /></a>
                            </li>

                            <li className={classes.PolicyItem}>
                                <a href="/policy/out-of-stock"><FormattedMessage id="out_of_stock" defaultMessage="Out of stock" /></a>
                            </li>

                            {
                                !config?.hiddenFooterPolicy && <React.Fragment>
                                     <li className={classes.PolicyItem}>
                                        <a href="/policy/wholesale-program"><FormattedMessage id="wholesale_policy" defaultMessage="Wholesale Policy" /></a>
                                    </li>

                                    <li className={classes.PolicyItem}>
                                        <a href="/policy/drop-shipping-policy"><FormattedMessage id="drop_shipping_policy" defaultMessage="Drop Shipping Policy" /></a>
                                    </li>
                                </React.Fragment>
                            }

                            <li className={classes.PolicyItem}>
                                <a href="/policy/how-to-pay-policy"><FormattedMessage id="payment_method" defaultMessage="Payment method" /></a>
                            </li>
                        </React.Fragment>
                    </PolicyAcc>
                </div>

                <div className={classes.BottomText}>
                    <div className={classes.FontLeft}>
                        <p className={classes.Font1}><FormattedMessage id="website_rights_reserved" values={{name:config?.name,domain:config?.root}} defaultMessage="{name} is a registered trademark of {domain}.All Rights Reserved." /></p>

                        <div className={classes.BottomPolicy}>
                            <a href="/policy/privacy-security-policy"><FormattedMessage id="privacy_policy" defaultMessage="Privacy &amp; Security Policy Terms" /></a>
                            <span>|</span>
                            <a href="/policy/terms-conditions-notice"><FormattedMessage id="term_notice" defaultMessage="Terms &amp; Conditions Notice" /></a>
                            <span>|</span>
                            <a href="/policy/copyright"><FormattedMessage id="i_p_rights" defaultMessage="Intellectual Property Rights" /></a>
                        </div>
                    </div>

                    <div className={classes.Image}>
                        <a href={`https://www.dmca.com/Protection/Status.aspx?ID=33a43ffc-bb02-4750-b2d4-26519aab26f8&amp;refurl=${config.root}`} target="_blank" title="DMCA.com Protection Status">
                            <img src="//images.dmca.com/Badges/_dmca_premi_badge_6.png?ID=33a43ffc-bb02-4750-b2d4-26519aab26f8" alt="" />
                        </a>
                    </div>
                </div>
            </div>

            <div className={classes.RightActivity}>
                <SocialContact />

                <SubscribeArea />

                <div className={classes.PaymentImage}>
                    <img src={payImage} alt="card" />
                </div>
            </div>
        </div>
    </footer>
}