import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { FormattedMessage } from 'react-intl'

export default props => {
	useStyles(classes)
	const { items, options, className, style, moreUrl, outline } = props
	return <Swiper
		{...options}
		className={className}
		style={style}
	>
		{
			items?.map((item, index) => <SwiperSlide className={`${classes.SwiperSlide} ${outline? classes.Outline: ''}`} key={index}>
				{
					props.render(item, index)
				}
			</SwiperSlide>)
		}
		{
			moreUrl && <SwiperSlide className={`${classes.SwiperSlide}`}>
				<a className={`${classes.ViewMoreProduct} ${outline? classes.Outline: ''}`} href={moreUrl}>
					<div className={classes.Text}>
						<FormattedMessage id="view_more" defaultMessage="View More" />{'>'}
					</div>
				</a>
			</SwiperSlide>
		}
	</Swiper>
}