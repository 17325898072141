import React, { useState, useEffect, useMemo } from "react";
import ReactDOM from 'react-dom'
import cls from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { BlackMask } from "../../../../components/mask";
import { Iconfont } from "../../../../components/icon/iconfont";
import { FormattedMessage } from "react-intl";
import CopyPageLink from "../../../../pages/details/tools/copy-page-link";
import ShareToWhatsapp from "../../../../pages/details/tools/share-to-whatsapp";
import ShareToMessage from "../../../../pages/details/tools/share-to-message";
import ShareToPinterest from "../../../../pages/details/tools/share-to-pinterest";
import ShareToFace from "../../../../pages/details/tools/share-to-face";
import { fetchShareUrl, getCouponByCouponMouldId } from "../../../../../api";
import moment from "moment";
import {IMAGE_GEEKO_LTD} from '../../../../../constants'

export const GiftCard = props => {
    useStyles(cls)
    const {coupon, toShare, showShareBtn, hideTime} = props

    return coupon && (
        <div className={cls.GiftCardCoupon} style={{...props?.style}} onClick={() => toShare(coupon)}>
            <div className={cls.GiftCardBox}>
                <div className={cls.GiftBox}>
                    <img src={`${IMAGE_GEEKO_LTD}/chicme/20230413/gift.png`} alt="" />
                </div>
                <div className={cls.GiftCardInfo}>
                    {
                        coupon?.name && <p className={cls.ItemTitle}>{coupon.name}</p>
                    }

                    {
                        coupon?.condition && <p className={cls.Desc1}>
                            <span dangerouslySetInnerHTML={{__html:coupon.condition}}></span>
                        </p>
                    }
                    {
                        coupon?.description && <p className={cls.Desc2}>
                            <span dangerouslySetInnerHTML={{__html:coupon.description}}></span>
                        </p>
                    }

                    {
                        coupon?.infoMsg && <p className={cls.Desc2}>
                            <span dangerouslySetInnerHTML={{__html:coupon.infoMsg}}></span>
                        </p>
                    }

                    {
                        showShareBtn && <div className={cls.ShareBtn}>
                            <FormattedMessage id="share" defaultMessage={"Share"}/>
                        </div>
                    }
                    {
                        !hideTime && coupon?.beginDate && coupon?.endDate && <p className={cls.DateDesc}>
                            <span className={cls.Dot}>·</span>
                            <span>{moment(coupon?.beginDate).format('DD/MM/YYYY HH:mm')}~{moment(coupon?.endDate).format('DD/MM/YYYY HH:mm')}</span>
                        </p>
                    }
                </div>
            </div>
        </div>
    )
}

export const ShareModal = props => {
    useStyles(cls)
    const {onClose, shareImageurl, sharePrefix, fetchParams, isShare} = props
    const [earnPoints,setEarnPoints] = useState(false)
    const [shareUrl, setShareUrl] = useState(null);

    useEffect(async () => {
        let response = await fetchShareUrl(fetchParams);
        if (response.code === 200) {
            setShareUrl(response?.result);
        } else {
            alert(response?.result);
        }
    }, []);

    const earnPointsEvent = () => {
        setEarnPoints(true);

        setTimeout(() => {
            setEarnPoints(false);
        }, 3000);
        // Toast("Copy successful");
    }

    const shareClickSensor = (platform) => {
        if(isShare) {
            if(window.GeekoSensors){
                window.GeekoSensors.Track("GiftCardShare",{
                    button: `share_${platform}`,
                })
            }
        }
    }

    return (
        <div className={cls.CouponShareBox}>
            <Iconfont className={cls.closeIcon} onClick={onClose}>&#xe69a;</Iconfont>
            <div className={cls.Hd}>
                <span><FormattedMessage id="share_to" defaultMessage="Share To" /></span>
            </div>

            {props?.children}

            <div className={cls.Bd}>
                <div className={cls.Item}>
                    <div className={`${cls.PluginContainer} ${cls.FacebookShare}`}>
                        <ShareToFace shareUrl={shareUrl?.facebook} onClick={() => shareClickSensor('facebook')}>
                            <span className={cls.FaceTool}>
                                <Iconfont className={cls.FaceIcon}>&#xe65e;</Iconfont>
                            </span>
                        </ShareToFace>
                        {/* <FacebookShareButton
                            url={shareUrl?.facebook}
                        >
                            <FacebookIcon size={50} round />
                        </FacebookShareButton> */}
                    </div>

                    <p>Facebook</p>
                </div>

                {
                    shareImageurl &&
                    <div className={cls.Item}>
                        <div className={`${cls.PluginContainer} ${cls.PinterestShare}`}>
                            <ShareToPinterest
                                shareUrl={shareUrl?.pinterest}
                                imageUrl={shareImageurl}
                                onClick={() => shareClickSensor('pinterest')}
                            >
                                <span className={cls.PinterTool}>
                                    <Iconfont className={cls.PinterIcon}>&#xe65f;</Iconfont>
                                </span>
                            </ShareToPinterest>

                            {/* <PinterestShareButton
                            >
                                <PinterestIcon size={50} round />
                            </PinterestShareButton> */}
                        </div>

                        <p>Pinterest</p>
                    </div>
                }

                <div className={cls.Item}>
                    <ShareToMessage
                        shareValue={`${sharePrefix?sharePrefix:""}${shareUrl?.message}`}
                        className={`${cls.MessageShare} ${cls.PluginContainer}`}
                        onClick={() => shareClickSensor('message')}
                    >
                        <Iconfont>&#xe784;</Iconfont>
                    </ShareToMessage>

                    <p>Message</p>
                </div>
                

                <div className={cls.Item}>
                    <ShareToWhatsapp
                        shareValue={`${sharePrefix?sharePrefix:""}${shareUrl?.whats_app}`}
                        className={`${cls.WhatsAppShare} ${cls.PluginContainer}`}
                        onClick={() => shareClickSensor('whatsapp')}
                    >
                        <Iconfont>&#xe785;</Iconfont>
                    </ShareToWhatsapp>

                    <p>WhatsApp</p>
                </div>

                <div className={cls.Item}>
                    <div className={cls.CopyBox}>
                        <CopyPageLink
                            className={`${cls.PluginContainer} ${cls.CopyLink}`}
                            successCallback={()=>earnPointsEvent()}
                            copyValue={shareUrl?.unknown}
                            onClick={() => shareClickSensor('copylink')}
                        >
                            <Iconfont>&#xe776;</Iconfont>
                        </CopyPageLink>
                    </div>

                    <p><FormattedMessage id="copy_link" defaultMessage="Copy Link" /></p>
                </div>
            </div>
            
            {
                earnPoints && <div className={cls.EarnPointsToast}>
                    <Iconfont>&#xe6b7;</Iconfont>
                    <span><FormattedMessage id="copy_success" defaultMessage="Copy successful" />!</span>
                </div>
            }
        </div>
    )
}


export const CouponShare = props => {
    const { coupon } = props;
    useStyles(cls);

    return (
        <ShareModal {...props}>
            <GiftCard coupon={coupon} toShare={() => {}} showShareBtn={false} hideTime={false}/>
        </ShareModal>
    )
}

export default props =>{
    useStyles(cls);
    const { couponMouldId, couponId } = props;
    // 根据couponId获取coupon信息
    // 给pcme用的分享优惠券弹窗

    const [show, setShow] = useState(false)
    const [coupon, setCoupon] = useState(null)

    const handleClose = (flag) => {
        setShow(false)
        iframeDispose();
    }

    useEffect(() => {
        shareCouponAlert()
    }, [])

    const shareCouponAlert = async () => {
        const res = await getCouponByCouponMouldId({couponMouldId: couponMouldId})
        if(res && res?.code == 200 && res?.result){
            // 根据id获取coupon信息
            setShow(true);
            setCoupon({...res?.result})
        } else {
            iframeDispose();
        }
    }

    // app或者嵌入iframe的地方调用外部关闭的通用方法
    const iframeDispose = () =>{
        window.parent?.closeHandle?.();
        return;
    }


    return <React.Fragment>
        {
            show && coupon && couponId && couponMouldId && ReactDOM.createPortal(<React.Fragment>
                <CouponShare
                    {...props}
                    isShare={true}
                    coupon={coupon}
                    onClose={handleClose}
                    fetchParams={{urlCode: 'CPN0', params: JSON.stringify({ couponId: couponId, couponMouldId: couponMouldId })}}
                    />
                <BlackMask onClick={handleClose} />
            </React.Fragment>, typeof document !== 'undefined' ? document.getElementById('root') : null)
        }
    </React.Fragment>
}