import React, { useEffect, useState } from 'react'
import ImageContainer from '../../../../../components/image/image-container'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ListSwiperProducts from './components/list-swiper-products/index';
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import withLastMoreGroup from '../../../../../pages/moduleable/modules/list/hoc/withLastMoreGroup'
import Product from '../../../../../desktop/pages/listing/components/item/index';
import { Types } from './index'
import withObserver from '../../../../../pages/moduleable/modules/hoc/withObserver'
import { getStyleObjectFromString } from '../../../../../utils/style'
import withSource from '../../../../../hocs/source-scoll'
import WithSwiper from './hoc/swiper'
import { ViewMoreProductItem } from '../../../../../desktop/pages/listing/components/item/index'


const Tab = withSource(props => {
    useStyles(classes)
    const {selectedItem, innerRef, sensors, item, handleClick, position} = props
    return <span
        ref={innerRef}
        data-position={`${sensors?.position || 1}-${(position || 0) + 1}`}
        data-type={sensors?.type}
        data-content={item?.refId || item.title || item.name}
        data-title={sensors?.title}
        onMouseOver={() => { handleClick(item, position) }} 
        className={`${classes.Tab} ${selectedItem?.id === item?.id ? classes.Selected : ''}`}
    ><span>{item.title || item.name}</span></span>
})

const Tabs = WithSwiper(props => {
    useStyles(classes)
    const { items } = props
    return <div className={`${classes.Tabs} ${classes.Group8Tabs}`}>
        {
            items?.map((item, index) => <Tab key={index} {...props} item={item} position={index}/>)
        }
    </div>
})


export default withLastMoreGroup(withObserver(props => {
    useStyles(classes)
    const { collections, data, sensors, innerRef } = props
    const [collection, setCollection] = useState()
    const [position, setPosition] = useState(1)
    const styles = getStyleObjectFromString(data?.style)

    const listStyles = getStyleObjectFromString(data?.listStyle);

    useEffect(() => {
        setCollection(collections?.[0])
    }, [collections])

    const aspectRatio = data?.width && data?.height ? `${data?.width} / ${data?.height}` : undefined

   
    return <div ref={innerRef} id={data?.id} className={ `${classes.ListContainer} ${!data?.full? 'MaxWith': ''}` } style={{ paddingTop: `${data?.marginTop}`, ...styles }}>

        {
            data?.styledTitle && <div className={classes.Title}>
                <span dangerouslySetInnerHTML={{ __html: data.styledTitle }} />
            </div>
        }

        {
            data?.src && <ImageContainer href={data?.href} style={{ aspectRatio, marginBottom: 12 }} position={0} sensors={{
                ...sensors,
                refId: data?.refId,
                type: '1',
            }}>
                <LazyLoadImage alt={data?.title} src={data?.src} />
            </ImageContainer>
        }

        <div>
            <Tabs
                sensors={
                    {
                        ...sensors,
                        type: '1',
                    }
                }
                items={collections} onTab={
                    (item, index) => {
                        setCollection(item)
                        setPosition(index+1)
                    }
                } />
        </div>

        <div className={classes.List4Products} style={{...listStyles}}>
            <ListSwiperProducts 
                options={{
                    loop:false
                }}
                items={collection?.products} 
                slidesPerView={5}
                render={(item, index)=>{
                    return <React.Fragment>
                        {
                            item?.id ? 
                            <Product sensors={
                                {
                                    resourcepage_title: sensors?.title,
                                    resource_position: `${sensors?.position}-${position}`,
                                    resource_content: collection?.refId || collection?.id,
                                    resource_type: '1',
                                }
                            } column={collection?.refId || collection?.id || data?.refId} product={item} position={index}/>:
                            <ViewMoreProductItem 
                                sensors={
                                    {
                                        resourcepage_title: sensors?.title,
                                        resource_position: `${sensors?.position}-${position}`,
                                        resource_content: collection?.refId || collection?.id,
                                        resource_type: '1',
                                    }
                            } column={collection?.refId || collection?.id || data?.refId} 
                                item={item} 
                                position={index} 
                            />
                        }
                    </React.Fragment>
                }}
            />
        </div>
    </div>
}))