// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2d66wvWtYEKaldWaxOwOUf{overflow:hidden;display:block;text-decoration:none}._2d66wvWtYEKaldWaxOwOUf img{display:block;width:100%}.wRVGQobO5f7FZ2dbOCmrg{display:block;text-decoration:none}.wRVGQobO5f7FZ2dbOCmrg ._343ndSdGEyKq6s_sgBC7yc{position:relative;overflow:hidden;display:block}.wRVGQobO5f7FZ2dbOCmrg ._343ndSdGEyKq6s_sgBC7yc>*{position:absolute;top:0;left:0;width:100%;display:block}\n", "",{"version":3,"sources":["webpack://src/client/components/image/style.module.scss"],"names":[],"mappings":"AAAA,yBACI,eAAgB,CAChB,aAAc,CACd,oBAAqB,CAHzB,6BAMQ,aAAc,CACd,UAAW,CACd,uBAID,aAAc,CACd,oBAAqB,CAFzB,gDAIQ,iBAAkB,CAClB,eAAgB,CAChB,aAAc,CANtB,kDAQY,iBAAkB,CAClB,KAAM,CACN,MAAO,CACP,UAAW,CACX,aAAc","sourcesContent":[".Container{\n    overflow: hidden;\n    display: block;\n    text-decoration: none;\n\n    img{\n        display: block;\n        width: 100%;\n    }\n}\n\n.ImageBlock{\n    display: block;\n    text-decoration: none;\n    .Image{\n        position: relative;\n        overflow: hidden;\n        display: block;\n        & > *{\n            position: absolute;\n            top: 0;\n            left: 0;\n            width: 100%;\n            display: block;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "_2d66wvWtYEKaldWaxOwOUf",
	"ImageBlock": "wRVGQobO5f7FZ2dbOCmrg",
	"Image": "_343ndSdGEyKq6s_sgBC7yc"
};
module.exports = ___CSS_LOADER_EXPORT___;
