// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cOHO3cA33HecLz9zRVYRa{position:relative;overflow:hidden;background-color:#efefef}.cOHO3cA33HecLz9zRVYRa img{display:block;position:absolute;left:0;top:0;width:100%}._1rmuStElOwyJ35fUCjNuBN{padding:0px 15px;background-color:#222;padding-bottom:40px}\n", "",{"version":3,"sources":["webpack://src/client/desktop/pages/moduleable/modules/image/style.module.scss"],"names":[],"mappings":"AAAA,uBACI,iBAAkB,CAClB,eAAgB,CAChB,wBAAyB,CAH7B,2BAMQ,aAAc,CACd,iBAAkB,CAClB,MAAO,CACP,KAAM,CACN,UAAW,CACd,yBAID,gBAAiB,CACjB,qBAAsB,CACtB,mBAAoB","sourcesContent":[".Image{\n    position: relative;\n    overflow: hidden;\n    background-color: #efefef;\n\n    img{\n        display: block;\n        position: absolute;\n        left: 0;\n        top: 0;\n        width: 100%;\n    }\n}\n\n.Image5ListContainer{\n    padding: 0px 15px;\n    background-color: #222;\n    padding-bottom: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Image": "cOHO3cA33HecLz9zRVYRa",
	"Image5ListContainer": "_1rmuStElOwyJ35fUCjNuBN"
};
module.exports = ___CSS_LOADER_EXPORT___;
