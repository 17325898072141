import Type5 from './type-5';
import Type3 from './type-3';

export const Types = {
    TYPES_3:"collections-3",
    TYPES_5: "collections-5"
}

export default type =>{
    switch(type){
        case Types.TYPES_3:
            return Type3
        case Types.TYPES_5:
            return Type5
        default:
            return ''
    }
}