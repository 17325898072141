import React, { useRef, useState } from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { unitPrice, getLowerPrice, getDelPrice, isPromotion, getProductUrl } from '../../../../../../utils/product'
import { useLanguage, useWebp } from '../../../../../hooks/common'
import withItemScroll from '../../../../../hocs/item-scroll'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { IMAGE_GEEKO_LTD } from '../../../../../../constants/index'
import { FormattedMessage } from 'react-intl'
import { getSensorsUrl } from '../../../../../utils/sensor'

export const EmptyItem = props => {
    useStyles(classes)
    return <figure className={`${classes.NormalItem}`}>
        <div>
            <div className={classes.Image}>
                {
                    <img alt="empty" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" />
                }
            </div>
        </div>
        <figcaption>
            <div className={`${classes.Prices} ${classes.LandingPrices}`}>
                <span className={classes.Price}>{'-'}</span>
            </div>
        </figcaption>
    </figure>
}


export default withItemScroll(props => {
    useStyles(classes)

    const { product, position, column, sensors, product_type, innerRef } = props

    const language = useLanguage()

    const { differentColorStyleVariants } = product

    const [selectedColor, setSelectedColor] = useState(differentColorStyleVariants?.find(v => v.productId === product.id))
    const [hovered, setHovered] = useState(false)

    const isWebp = useWebp() && selectedColor?.status === '1'

    const [lowerPrice, delPrice, isProm] = [
        getLowerPrice(product),
        getDelPrice(product),
        isPromotion(product)
    ]

    let off = 0
    if (delPrice) {
        off = Math.round((Number(delPrice.amount) - Number(lowerPrice.amount)) * 100 / Number(delPrice.amount))
    }


    const url = getProductUrl(selectedColor?.id || product?.id, product?.name, selectedColor?.color, '', language)


    const promotionType = isProm ? product.promotion.type : ''

    const isActivePromotion = isProm && !product?.promotion?.countdownHidden

    const priceClass = isActivePromotion ? classes.FlashPrice : (off > 0 ? classes.PromotionPrice : classes.Price)

    const imageUrl = `${IMAGE_GEEKO_LTD}${isWebp ? '/webp' : ''}/medium/${selectedColor?.mainImage || product?.pcMainImage}`
    const secondaryImageUrl = `${IMAGE_GEEKO_LTD}${isWebp ? '/webp' : ''}/medium/${selectedColor?.pcMainImage2 || product?.pcMainImage2}`

    const handleProductClick = e => {
		e.preventDefault()
		const href = e.currentTarget?.href
		window.location.href = getSensorsUrl(href, {
			resourcepage_title: sensors?.resourcepage_title,
			resource_type: sensors?.resource_type,
			resource_content: sensors?.resource_content,
			resource_position: sensors?.resource_position,
			product_position: position + 1,
		})
	}


    return <figure className={`${classes.NormalItem} ${position === 0 ? classes.FirstImage : ''}`}>
        <a
            href={url} ref={innerRef}
            product-id={product?.id}
            data-product-list-source
            data-promotion={promotionType}
            data-layer={product?.layerName}
            data-tracking={product.trackingRecords}
            data-product-source={product.dataSource}
            data-geeko-experiment={product.geekoExperimentId}
            data-geeko-id={product.geekoRequsestId}
            data-request-id={product.aliRequestId}
            data-experiment-id={product.aliExperimentId}
            data-column={column}
            data-product-position={position + 1}
            data-position={sensors?.resource_position}
			data-type={sensors?.resource_type}
			data-content={sensors?.resource_content}
			data-title={sensors?.resourcepage_title}
            data-product-type={product_type}
            onClick={handleProductClick}
        >

            <div onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                className={classes.Image}>

                {
                    position === 0 ? <img src={hovered && secondaryImageUrl ? secondaryImageUrl : imageUrl} alt={product?.name} /> : <LazyLoadImage src={hovered ? secondaryImageUrl : imageUrl} alt={product?.name} />
                }


                {
                    product.combinatorialPromotion && product.combinatorialPromotion.enabled && <div className={classes.CombinatorialPromotion}>
                        {product?.combinatorialPromotion?.title}
                    </div>
                }

                {
                    product.flag ? (
                        <span className={`${classes.OffTip} ${classes.PreFall}`}>{product.flag}</span>
                    ) : (
                        <React.Fragment>
                            {
                                product.isNew && <span className={classes.New}><FormattedMessage id="new" defaultMessage="New" /></span>
                            }

                            {
                                !product.isNew && isActivePromotion && <span style={{ backgroundColor: '#fed157', color: '#222' }} className={classes.OffTip}>{`-${off}%`}</span>
                            }

                            {
                                !product.isNew && !isActivePromotion && off > 0 && <span className={classes.OffTip}>{`-${off}%`}</span>
                            }
                            {/* {
                                !product.isNew && off > 0 && <span className={classes.BlackFridayIcon}>{`-${off}%`}</span>
                            } */}
                        </React.Fragment>
                    )
                }
            </div>
        </a>
        <figcaption>
            <div className={`${classes.Prices} ${classes.LandingPrices}`}>
                <span className={priceClass}>{unitPrice(lowerPrice)}</span>
                {
                    delPrice && <del className={classes.Del}>{unitPrice(delPrice)}</del>
                }
            </div>
        </figcaption>
    </figure>
})