import React from 'react'

const __dayoffset = 1000 * 60 * 60 * 24, __houroffset = 1000 * 60 * 60, __minuteoffset = 1000 * 60
const getFullNumber = (number) => {
	let values;
	if(number >= 10){
		let str = String(number);
		let arrs = str.split("");
		if(arrs?.length > 2){
			values = {
				hundreds:arrs?.[0],
				decade: arrs?.[1],
				unit: arrs?.[2]
			};
		}else{
			values = {
				decade: arrs?.[0],
				unit: arrs?.[1]
			};
		}
	}else{
		values = {
			decade: 0,
			unit: number
		};
	}
	return values;
}

export default class extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			lefting: 0
		}
	}

	getStructeDate(lefting) {
		const day = Math.floor(lefting / __dayoffset)
		const _leftday = lefting % __dayoffset
		let hour = this.props.showDay ? Math.floor(_leftday / __houroffset) : Math.floor(lefting / __houroffset)
		const _lefthour = lefting % __houroffset
		const minute = Math.floor(_lefthour / __minuteoffset)
		const second = Math.floor((lefting % __minuteoffset) / 1000)

		return {
			day: getFullNumber(day),
			hour: getFullNumber(hour),
			minute: getFullNumber(minute),
			second: getFullNumber(second)
		}
	}

	componentDidMount() {
		const { offset } = this.props
		this.setState({
			lefting: offset
		})
		this.timerID = setInterval(() => {
			if (this.state.lefting - 1000 < 0) {
				clearInterval(this.timerID)
			} else {
				this.setState({
					lefting: this.state.lefting - 1000
				})
			}
		}, 1000)
	}

	componentWillUnmount() {
		clearInterval(this.timerID)
	}

	render() {
		const { day, hour, minute, second } = this.getStructeDate(this.state.lefting)
		const { showDay, showHour, showLabel, offset, numbereStyle, labelStyle, dotStyle, numberClass, labelClass, dotClass } = this.props
		return <React.Fragment>
			{
				offset >= 1000 && <span {...this.props}>
					{showDay && day!=='00' && <React.Fragment><span className={numberClass} style={numbereStyle} data-decade-number>{day?.decade}</span><span className={numberClass} style={numbereStyle} data-unit-number>{day?.unit}</span>{showLabel && <span className={labelClass} style={labelStyle} data-label>d</span>}<span className={dotClass}  style={dotStyle} data-dot>:</span></React.Fragment>}
					{showHour && <React.Fragment>{hour.hundreds && <span className={numberClass} style={numbereStyle} data-decade-number>{hour.hundreds}</span>}<span className={numberClass} style={numbereStyle} data-decade-number>{hour?.decade}</span><span className={numberClass} style={numbereStyle} data-unit-number>{hour?.unit}</span>{showLabel && <span className={labelClass} style={labelStyle} data-label>h</span>}<span className={dotClass}  data-dot style={dotStyle}>:</span></React.Fragment>}
					<span className={numberClass} style={numbereStyle} data-decade-number>{minute?.decade}</span><span className={numberClass} style={numbereStyle} data-unit-number>{minute?.unit}</span>{showLabel && <span className={labelClass} style={labelStyle} data-label>m</span>}<span className={dotClass} style={dotStyle} data-dot>:</span>
					<span className={numberClass} style={numbereStyle} data-decade-number>{second?.decade}</span><span className={numberClass} style={numbereStyle} data-unit-number>{second?.unit}</span>{showLabel && <span className={labelClass} style={labelStyle} data-label>s</span>}
				</span>
			}
		</React.Fragment>
	}
}


