import React, {createRef, useEffect} from 'react'

export default Wrapped => {

    return props => {
        const ref = createRef()
        let inited = false;

        useEffect(() => {
            let ob = new IntersectionObserver(obHandle)
            ob.observe(ref.current)
            return () => {
                ob.disconnect()
            }
        }, [])

        const obHandle = entries => {
            const entry = entries?.[0]
            if (entry?.intersectionRatio > 0 && !inited) {
                inited = true;
                props?.initLoad();
            }
        }

        return <Wrapped innerRef={ref} {...props}/>
    }
}