import React from "react";
import { PinterestShareButton } from "react-share";

export default props =>{
    return <PinterestShareButton
        url={props?.shareUrl}
        media={props?.imageUrl}
        className={props?.className}
    >
        {props.children}
    </PinterestShareButton>
}