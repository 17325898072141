
import React, { useState } from "react";
import classes from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import deeplink from "../../../../../../utils/deeplink";
import Cookies from 'js-cookie'
import { useEffect } from "react";
import { Iconfont } from "../../../../../components/icon/iconfont";
import { BlackMask } from "../../../../../components/mask";
import CountDown from "../../../../../components/count-down/count-down";
import { FormattedMessage } from "react-intl";
import Cache from "../../../../../../utils/cache";
import withSource from '../../../../../hocs/source-scoll'
import { getSensorsUrl } from "../../../../../utils/sensor";

const AlertDiscountItem = withSource(props =>{
    const { message, onClose, innerRef} = props;
    const { backgroundImage, endDate, serverTime, style, refId } = message;

    let offset = 0;
    if(endDate && serverTime){
        offset = message?.endDate - message?.serverTime;
    }

    const handleClose = () =>{
        onClose();

        if(window.GeekoSensors){
			window.GeekoSensors.Track('PopUp', {
				type: '促销',
				page_content:"popup",
				popupclose: true,
			})
		}
    }

    const normalDiscountEvent = (event) =>{
        event.preventDefault();
        const { currentTarget } = event;

        if(window.GeekoSensors){
            window.GeekoSensors.Track('PopUp', {
                type: '促销',
                page_content:"popup",
                is_click:true
            })
        }

        window.location.href = getSensorsUrl(currentTarget?.href, {
			resourcepage_title: currentTarget.getAttribute("data-title"),
			resource_type: currentTarget.getAttribute("data-type"),
            resource_position: currentTarget.getAttribute("data-position"),
			resource_content: currentTarget.getAttribute("data-content"),
		})
    }

    return <React.Fragment>
        <div className={classes.AlertDiscount}>
            <a
                onClick={normalDiscountEvent}
                className={classes.AlertHref}
                href={deeplink(message?.deepLink, "pc")}
                ref={innerRef}
                data-title={'popup-activity'}
                data-position={'1'}
                data-type={'22'}
                data-content={refId}
            >
                <img src={backgroundImage?.url} />
            </a>
            <Iconfont className={classes.Close} onClick={handleClose}>&#xe69a;</Iconfont>

            {
                offset > 1000 && <div className={`${classes.CountDownContainer}`}>
                    <div className={classes.EndsIn} style={{color:`${style?.timeDownBackgroudColor || '#222'}`}}>
                        <FormattedMessage id="ends_in" defaultMessage="Ends in" />
                    </div>

                    <div className={classes.CountDown}>
                        <CountDown
                            offset={offset}
                            showHour
                            dotStyle={{color:style?.timeDownBackgroudColor || '#222'}}
                            numbereStyle={{color:style?.timeDownFontColor || '#fff', backgroundColor: style?.timeDownBackgroudColor || '#222'}}
                        />
                    </div>
                </div>
            }
        </div>

        <BlackMask onClick={handleClose} />
    </React.Fragment>
})

const PinterestDiscountItem = props =>{
    const { message, onClose } = props;
    const { backgroundImage, endDate, serverTime, style } = message;

    let offset = 0;
    if(endDate && serverTime){
        offset = message?.endDate - message?.serverTime
    }

    return <React.Fragment>
        <div className={classes.AlertDiscount}>
            <div className={classes.AlertHref} onClick={onClose}>
                <img src={backgroundImage?.url} />
            </div>
            <Iconfont className={classes.Close} onClick={onClose}>&#xe69a;</Iconfont>

            {
                offset > 1000 && <div className={`${classes.CountDownContainer} ${classes.Pinterest}`}>
                    <div className={classes.EndsIn} style={{color:`${style?.timeDownBackgroudColor || '#222'}`}}>
                        <FormattedMessage id="ends_in" defaultMessage="Ends in" />
                    </div>

                    <div className={classes.CountDown}>
                        <CountDown
                            offset={offset}
                            showHour
                            dotStyle={{color:style?.timeDownBackgroudColor || '#222'}}
                            numbereStyle={{color:style?.timeDownFontColor || '#fff', backgroundColor: style?.timeDownBackgroudColor || '#222'}}
                        />
                    </div>
                </div>
            }
        </div>

        <BlackMask onClick={onClose} />
    </React.Fragment>
}

const AlertDiscount = props =>{
    const {handleClose} = props
    useStyles(classes);
    const [show,setShow] = useState(false);

    useEffect(() =>{
        const storedIndexAlert = Cache.get('index-alert')
        if(!storedIndexAlert){
            Cache.set('index-alert', 1)
            setShow(true);
        }
    },[]);

    return show && <AlertDiscountItem {...props} onClose={()=>{setShow(false); handleClose()}} />
}

export const PinterestDiscount = props =>{
    const {handleClose} = props
    useStyles(classes);

    return <PinterestDiscountItem {...props} onClose={handleClose}/>
}

export default AlertDiscount;