// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3vl31mUnAb2SNa4XDcCPKd{display:flex;align-items:center;justify-content:center;margin-left:auto;margin-right:auto;max-width:1348px}._3vl31mUnAb2SNa4XDcCPKd ._3Fw20Yh5ywJAD_DjpBmDhf{color:#4b5056;text-decoration:none}._3vl31mUnAb2SNa4XDcCPKd ._3Fw20Yh5ywJAD_DjpBmDhf img{width:100%;display:block}._3vl31mUnAb2SNa4XDcCPKd ._3W_KnOdg2Z0-orl_fWSL6Q{width:1290px}\n", "",{"version":3,"sources":["webpack://src/client/desktop/pages/moduleable/modules/vendors/style.module.scss"],"names":[],"mappings":"AAAA,yBACI,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CACvB,gBAAiB,CACjB,iBAAkB,CAClB,gBAAiB,CANrB,kDASQ,aAAc,CACd,oBAAqB,CAV7B,sDAaY,UAAW,CACX,aAAc,CAd1B,kDAmBQ,YAAa","sourcesContent":[".ListContainer{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-left: auto;\n    margin-right: auto;\n    max-width: 1348px;\n\n    .VendorLink{\n        color: #4b5056;\n        text-decoration: none;\n\n        img{\n            width: 100%;\n            display: block;\n        }\n    }\n\n    .SwiperContainer{\n        width: 1290px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ListContainer": "_3vl31mUnAb2SNa4XDcCPKd",
	"VendorLink": "_3Fw20Yh5ywJAD_DjpBmDhf",
	"SwiperContainer": "_3W_KnOdg2Z0-orl_fWSL6Q"
};
module.exports = ___CSS_LOADER_EXPORT___;
