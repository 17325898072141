import React, { useEffect, useState } from 'react'
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from './style.module.scss'
import { fetchGetLotteryPrizes, fetchGetUserLastTimes, fetchGetMessage, fetchGetMyPrizes, fetchLuckLastesthistory, fetchLuckDraw } from '../../../../../../../api'
import JiugonggeContainer from '../components/jiugongge-container'
import { useSelector } from 'react-redux'
import LotteryResult from '../lottery-result'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FormattedMessage, injectIntl, useIntl } from 'react-intl'
import { Iconfont } from '../../../../../../components/icon/iconfont'
import { v4 } from 'uuid'
import Cache from '../../../../../../../utils/cache'
import { IMAGE_GEEKO_LTD } from '../../../../../../../constants'
import { useDispatch } from 'react-redux'
import { action_add_modal_gift_list, action_fetch_gifts_info_message } from '../../../../../../../store/actions/pages/collection'
import { GiftsModal } from '../../../../../pages/details/components/product-main-container/right-message/product-off-info'

let prize = 0;
let lotteryCount = 0;
const JGG_box = (props) => {
    const {onResult, prizes, isLogin, setShowGiftModal, getGiftData, loginUrl} = props
    const intl = useIntl();

    const [lotteryResult, setLotteryResult] = useState(null)
    const numFormate = (num) => (num / 1920 * 100) + 'vw'

    const activePrizeback = {
        src: `${IMAGE_GEEKO_LTD}/chicme/2021111101/black-friday/gift-no-pick.png`,
        activeSrc: `${IMAGE_GEEKO_LTD}/chicme/2021111101/black-friday/gift-pick.png`,
        width: '100%',
        height: '100%'
    }
    const jiugonggeInit = {
        width: numFormate(604),
        height: numFormate(692),
        blocks: [{
            padding: `${numFormate(140)} ${numFormate(60)} ${numFormate(60)}`,
            imgs: [{
                src: `${IMAGE_GEEKO_LTD}/chicme/2021111101/black-friday/sudoku_draw.png`,
                width: '100%',
                height: '100%'
            }],
        }],
        defaultConfig:{
            gutter: 10
        },
        defaultStyle: {
            borderRadius: 10,
            background: "#F70F0F",
            fontColor: '#fff', 
            fontSize: numFormate(16), 
            fontStyle: 'Arial',
            fontWeight: 'bold',
        },
        prizes: [
            // imgs里面 第一个是背景图 第二个是产品图
            { x:0, y:0, fonts: [{text:'200 points',top: '70%'}], imgs: [activePrizeback,{src:`${IMAGE_GEEKO_LTD}/chicme/2021111101/black-friday/Beauty-Box.png`,width:'80%',height:'70%', top: '10%'}]},
            { x:1, y:0, fonts: [{text:'50 points',top: '70%'}], imgs: [activePrizeback,{src:`${IMAGE_GEEKO_LTD}/chicme/2021111101/black-friday/Cash.png`,width:'80%',height:'70%', top: '10%'}]},
            { x:2, y:0, fonts: [{text:'100 points',top: '70%'}], imgs: [activePrizeback,{src:`${IMAGE_GEEKO_LTD}/chicme/2021111101/black-friday/Dyson-Airwrap.png`,width:'80%',height:'70%', top: '10%'}]},
            { x:2, y:1, fonts: [{text:'50% OFF',top: '70%'}], imgs: [activePrizeback,{src:`${IMAGE_GEEKO_LTD}/chicme/2021111101/black-friday/Mystery-Gift.png`,width:'80%',height:'70%', top: '10%'}]},

            { x:2, y:2, fonts: [{text:'50 points',top: '70%'}], imgs: [activePrizeback,{src:`${IMAGE_GEEKO_LTD}/chicme/2021111101/black-friday/Thanks.png`,width:'80%',height:'70%', top: '10%'}]},
            { x:1, y:2, fonts: [{text:'thanks',top: '70%'}], imgs: [activePrizeback,{src:`${IMAGE_GEEKO_LTD}/chicme/2021111101/black-friday/Xmas-Set.png`,width:'80%',height:'70%', top: '10%'}]},
            { x:0, y:2, fonts: [{text:'anniversary gift',top: '70%'}], imgs: [activePrizeback,{src:`${IMAGE_GEEKO_LTD}/chicme/2021111101/black-friday/pts.png`,width:'80%',height:'70%', top: '10%'}]},
            { x:0, y:1, fonts: [{text:'40% OFF',top: '70%'}], imgs: [activePrizeback,{src:`${IMAGE_GEEKO_LTD}/chicme/2021111101/black-friday/pts.png`,width:'80%',height:'70%', top: '10%'}]},

        ],
        buttons: [
            { x:1,y:1, background:'#F70F0F', imgs:[{src:`${IMAGE_GEEKO_LTD}/chicme/2021111101/black-friday/go.png`, top: '5%', width: '90%'}] },
        ],
        activeStyle:{
            background: '#610306',
            shadow: "0px 0px 8px #e41e0d",
        }
    }; // 九宫格抽初始化 

    useEffect(() => {
        getUserLastTimes()
    },[])

    useEffect(() => {
        if (!isLogin) {
            lotteryCount = '?'
        } else {
            getUserLastTimes();
        }
    }, []);

    // 已登录点击抽奖
    const handleBegin = () => {
        if(!isLogin){
            window.location.href = '/i/login?redirectUrl=' + loginUrl
            return
        }
        let luckShowed = Cache.get("__luckshowed");
        // 以下两行是测试代码 上之前记得注释掉
        // onStart();
        // return;
        //无抽奖机会提示
        if (lotteryCount == 0 || lotteryCount == '?') {

            let modalData = {
                btn2: {
                    label: intl.formatMessage({ id: "ok", defaultMessage: "OK" }),
                    callback: () => {
                        onResult(null);
                    }
                },
                font1: {
                    label: intl.formatMessage({ id: "today_no_change", defaultMessage: "You've run out of chances today, go shopping!" }, { name: global?.config?.name }),
                    style: {
                        fontSize: "1vw",
                        margin: "15px 15px 0",
                        textAlign: "center",
                    }
                },
                imageUrl: "/chicme/2022052401/no_times_mask.png",
            };
            onResult(modalData)
            return;
        }else{
            if(lotteryCount > 0){
                onStart();
            }

            if (!luckShowed) {
                Cache.set("__luckshowed", 1, 24 * 60 * 60);
            }
        }

        if (window.GeekoSensors) {
            window.GeekoSensors.Track('PopUp', {
                type: "转盘抽奖",
                page_content: "popup",
                is_join: true
            })
        }
    };

    const onStart = async (props) => {
       
        setLotteryResult(null)
        const randomid = v4();
        // 以下代码到return是测试代码 上之前记得注释掉
        // if (prize >= 0) {
        //     setLotteryResult(prize)
        //     prize += 1
        //     if(prize > 7){
        //         prize = 0
        //     }
        // }
        // return 
        let response = await fetchLuckDraw(randomid);
        if (response?.code === 200) {
            let result = response?.result;

            let prize = prizes?.findIndex(item => item?.id === result?.id);
            if (prize >= 0) {
                setLotteryResult(prize)
            }

            getUserLastTimes();

            if (window.GeekoSensors) {
                let lottery_result = response?.result?.name || '接口返回为空'
                if(response?.result?.physicalInfo){
                    lottery_result += '_' + response?.result?.physicalInfo
                }
                window.GeekoSensors.Track('PopUp', {
                    type: "转盘抽奖",
                    lottery_result: lottery_result,
                })
            }
        } else {
            alert(response?.result);
            getUserLastTimes();
        }
    }

    const onEnd = (prize) => {
        // prize为抽奖结果
        // 1积分 2优惠券 3实物 4谢谢惠顾 5送到卡包里的现金券 6实物集合 7送到钱包的现金券
        let lotteryResult = prize?.data;
        let modalItem;
        if (lotteryResult?.type == 1) {
            modalItem = {
                btn1: {
                    label: intl.formatMessage({ id: "got_it", defaultMessage: "GOT IT" }),
                    callback: () => {
                        onResult(null)
                    }
                },
                btn2: {
                    label: intl.formatMessage({ id: "checkout", defaultMessage: "CHECKOUT" }),
                    callback: () => {
                        onResult(null)
                        window.location.href = '/cart';
                        return
                    }
                },
                font1: {
                    label: intl.formatMessage({ id: "congratulations", defaultMessage: "Congratulations" }),
                    style: {
                        fontSize: "1vw"
                    }
                },
                font2: {
                    label: lotteryResult?.name,
                    style: {
                        fontSize: "30px",
                        marginTop: "10px",
                        textTransform: "uppercase"
                    }
                },
                showTimes: ((24 * 60 * 60 * 1000) * 1),
                imageUrl: lotteryResult?.imageUrl
            };
        } else if (lotteryResult?.type == 2) {
            modalItem = {
                btn1: {
                    label: intl.formatMessage({ id: "got_it", defaultMessage: "GOT IT" }),
                    callback: () => {
                        onResult(null)
                    }
                },
                btn2: {
                    label: intl.formatMessage({ id: "checkout", defaultMessage: "CHECKOUT" }),
                    callback: () => {
                       onResult(null)
                       window.location.href = '/cart'
                       return
                    }
                },
                font1: {
                    label: intl.formatMessage({ id: "congratulations", defaultMessage: "Congratulations" }),
                    style: {
                        fontSize: "1vw"
                    }
                },
                font2: {
                    label: lotteryResult?.name,
                    style: {
                        fontSize: "30px",
                        marginTop: "10px",
                        textTransform: "uppercase"
                    }
                },
                showTimes: ((24 * 60 * 60 * 1000) * 1),
                imageUrl: lotteryResult?.imageUrl
            };
        } else if(lotteryResult?.type == 5 || lotteryResult?.type == 7) {
            modalItem = {
                btn1: {
                    label: intl.formatMessage({ id: "got_it", defaultMessage: "GOT IT" }),
                    callback: () => {
                        onResult(null)
                    }
                },
                btn2: {
                    label: intl.formatMessage({ id: "checkout", defaultMessage: "CHECKOUT" }),
                    callback: () => {
                        onResult(null)
                        if(lotteryResult?.type == 5){
                            // window.location.href = '/me/m/coupons'
                            window.location.href = '/cart'
                            return
                        } else {
                            window.location.href = '/me/m/wallet'
                            return
                        }
                        // window.location.href = '/cart'
                    }
                },
                font1: {
                    label: intl.formatMessage({ id: "congratulations", defaultMessage: "Congratulations" }),
                    style: {
                        fontSize: "1vw",
                        marginTop: '15px'
                    }
                },
                font2: {
                    label: lotteryResult?.name,
                    style: {
                        fontSize: "30px",
                        marginTop: "10px",
                        textTransform: "uppercase"
                    }
                },
                showTimes: ((24 * 60 * 60 * 1000) * 1),
                imageUrl: lotteryResult?.imageUrl,
                // cashCoupon: true
            };
        } else if (lotteryResult?.type == 3) {
            modalItem = {
                btn1: {
                    label: intl.formatMessage({ id: "got_it", defaultMessage: "GOT IT" }),
                    callback: () => {
                        onResult(null)
                    }
                },
                btn2: {
                    label: intl.formatMessage({ id: "checkout", defaultMessage: "CHECKOUT" }),
                    callback: () => {
                        onResult(null)
                        window.location.href = '/cart'
                        return
                    }
                },
                font1: {
                    label: intl.formatMessage({ id: "congratulations", defaultMessage: "Congratulations" }),
                    style: {
                        fontSize: "1vw",
                        marginTop:"10px"
                    }
                },
                font2: {
                    label: lotteryResult?.name,
                    style: {
                        fontSize: "30px",
                        marginTop: "10px",
                        textTransform: "uppercase"
                    }
                },
                font3: {
                    label: intl.formatMessage({id: 'gift_to_your_bag'}),
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Roboto-Regular, Roboto',
                        fontWeight: 400,
                        color:'#222222',
                        lineHeight: '14px',
                    }
                },
                imageUrl: lotteryResult?.imageUrl,
                // showTimes: ((24 * 60 * 60 * 1000) * 1),
            }
        } else if (lotteryResult?.type == 6) {
            getGiftData('1u6g5A8H8j2o1f9Z2g2M60H4j')
            modalItem = {
                btn1: {
                    label: intl.formatMessage({ id: "got_it", defaultMessage: "GOT IT" }),
                    callback: () => {
                        onResult(null)
                    }
                },
                btn2: {
                    label: intl.formatMessage({id: 'pick_my_gift', defaultMessage: 'Pick My Gift'}),
                    callback: () => {
                        onResult(null)
                        setShowGiftModal({giftCollectionId:'1u6g5A8H8j2o1f9Z2g2M60H4j'})
                        return
                    }
                },
                font1: {
                    label: intl.formatMessage({ id: "congratulations", defaultMessage: "Congratulations" }),
                    style: {
                        fontSize: "1vw",
                        marginTop:"10px"
                    }
                },
                font2: {
                    label: lotteryResult?.name,
                    style: {
                        fontSize: "30px",
                        marginTop: "10px",
                        textTransform: "uppercase"
                    }
                },
                font3: {
                    label: intl.formatMessage({ id: "choose_add_your_cart", defaultMessage: "Please choose your favorite gift to add to your cart." }),
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Roboto-Regular, Roboto',
                        fontWeight: 400,
                        color:'#222222',
                        lineHeight: '14px',
                    }
                },
                font4: {
                    label: '*' + intl.formatMessage({id: 'reselect_gift_your_bag', defaultMessage:'You can reselect the gift in your bag.'}) ,
                    style: {
                        fontSize: 20,
                        fontFamily: 'Roboto-Regular, Roboto',
                        fontWeight: 400,
                        color: '#999999',
                        lineHeight: '20px',
                        transform: 'scale(0.5)'
                    }
                },
                imageUrl: lotteryResult?.imageUrl,
                showTimes: ((24 * 60 * 60 * 1000) * 1),
            }
        } else if (lotteryResult?.type == 7) {
            modalItem = {
                btn1: {
                    label: intl.formatMessage({ id: "got_it", defaultMessage: "Got it" }),
                    isUppercase:true,
                    callback: () => {
                        onResult(null)
                    }
                },
                btn2: {
                    label: intl.formatMessage({ id: "view_prizes", defaultMessage: "View Prizes" }),
                    isUppercase:true,
                    callback: () => {
                        onResult(null)
                        window.location.href = "/me/m/wallet"
                        return 
                    }
                },
                font1: {
                    label: intl.formatMessage({ id: "congratulations", defaultMessage: "Congratulations" }),
                    style: {
                        fontSize: "1vw",
                        marginTop:"10px"
                    }
                },
                imageUrl: lotteryResult?.imageUrl,
                // imageWidth:"35%",
                showTimes: 24 * 60 * 60 * 1000,
            }
        } else {
            modalItem = {
                btn2: {
                    label: intl.formatMessage({ id: "ok", defaultMessage: "OK" }),
                    callback: () => {
                        onResult(null)
                    }
                },
                font1: {
                    label: `${intl.formatMessage({ id: "thanks", defaultMessage: "Thanks" })}!`,
                    style: {
                        fontSize: "1vw",
                        fontFamily: 'Roboto-Medium'
                    }
                },
                font2: {
                    label: `${intl.formatMessage({ id: "please_try_again", defaultMessage: "Oops, please try again" })}!`,
                    style: {
                        fontSize: "1vw"
                    }
                },
                imageUrl: lotteryResult?.imageUrl
            };
        }
        onResult(modalItem)
        return
    }


    // 请求用户剩余次数
    const getUserLastTimes = async () => {
        let response = await fetchGetUserLastTimes();
        if (response?.code === 200) {
            lotteryCount = response?.result
        }
    }

    

    return <div className={classes.JGG_box}>
        <img className={classes.JGG_cirle_line_img} src={`${IMAGE_GEEKO_LTD}/chicme/2021111101/black-friday/decorate_draw.png`} alt="" />
        <div className={classes.JGG_Main_box}>
            <div className={classes.JGG_header_img}>
                <img src={`${IMAGE_GEEKO_LTD}/chicme/2021111101/black-friday/copy_writer_draw2.png`} alt="" />
                <span className={classes.JGG_header_txt}><FormattedMessage id="lucky_draw_nobreak" defaultMessage={"Lucky Draw"}/></span>
            </div>
            <div className={classes.JGG_Main_back_box}>
                <div className={classes.JGG_Main}>
                    <div className={classes.JGG_last_chance}>
                        <FormattedMessage id="you_have" defaultMessage={"You Have"}/> {lotteryCount} <FormattedMessage id="changes" defaultMessage={"Chance"}/>
                    </div>
                    {
                        props.prizes &&
                        <JiugonggeContainer 
                            onStart={() => handleBegin()}
                            onEnd={(res) => onEnd(res)}
                            prizes={props.prizes}
                            jiugonggeInit={jiugonggeInit}
                            jiugonggeResult={lotteryResult}
                            />
                    }
                </div>
            </div>
        </div>

        
    </div>
}

const JGG_rule = (props) => {

    const {isLogin, loginUrl} = props

    const [ type, setType ] = useState('winner')

    const changeType = (t) => {
        if (type == t) return
        if(t == 'my'){
            if(!isLogin){
                window.location.href = '/i/login?redirectUrl=' + loginUrl
                return 
            }
            // props.getMyPrize()
        } else {
            props.getDrawHistory()
        }
        setType(t)
    }

    return <div className={classes.JGG_rule}>
        <img className={classes.JGG_rule_back_img} src="https://s3.us-west-2.amazonaws.com/image.chic-fusion.com/chicme/20220801/rules-back.png" alt="" />
        <div className={classes.JGG_rule_box}>
            <div className={classes.JGG_rule_title}>
                <FormattedMessage id="rules" defaultMessage={"Rules"}/>
            </div>
            <LotteryRules />
        </div>

        <div className={classes.JGG_winner}>
            <div className={classes.JGG_winner_title}>
                <div className={`${classes.JGG_winner_title_item} ${type=='winner' && classes.JGG_winner_title_selected}`} onClick={() => changeType('winner')}><FormattedMessage id="lucky_winner" defaultMessage={"LUCKY WINNER"}/></div>
                <div className={`${classes.JGG_winner_title_item} ${type=='my' && classes.JGG_winner_title_selected}`} onClick={() => changeType('my')}><FormattedMessage id="my_prize" defaultMessage={"MY PRIZE"}/></div>
            </div>
            <div className={classes.JGG_winnerContent}>
                {
                    type=='winner'?
                    <WinnerSwiper drawHistory={props.drawHistory}/>:
                    <MyPrize />
                }
            </div>
        </div>
    </div>
}

const LotteryRules = props => {
    const [rules, setRules] = useState(null);

    useEffect(async () => {
        let response = await fetchGetMessage("M1586");

        if (response?.code === 200) {
            if (response?.result?.message) {
                let data = JSON.parse(response?.result?.message);
                setRules(data?.rules);
            }
        }
    }, []);


    return <div className={classes.LotteryRules}>
        <div className={classes.RulesBox}>
            <div className={classes.Content}>
                {
                    rules?.length > 0 ? (
                        rules?.map((rule, index) => {
                            return (
                                <div className={classes.RulesItem} key={rule + index}>
                                    <span className={classes.RulesNum}>
                                        {index + 1}.
                                    </span>

                                    <span className={classes.RulesFont}>
                                        {
                                            rule?.title instanceof Array ? (
                                                rule?.title.map((item, index) => <span key={index}>{item}</span>)
                                            ) : (<span>{rule?.title}</span>)
                                        }
                                    </span>
                                </div>
                            )
                        })
                    ) : (
                        <div className={classes.Loading}><FormattedMessage id="loading" defaultMessage="loading" />...</div>
                    )
                }
            </div>
        </div>
    </div>
}

const WinnerSwiper = props => {

    const { drawHistory } = props;

    return <>
        {
            drawHistory?.length > 0 && <div className={classes.LuckDrawHistory}>
                <Swiper
                    direction='vertical'
                    loop={true}
                    autoplay={{ disableOnInteraction: false, delay: 3000 }}
                    onSlideChange={(swiper) => console.log()}
                    onSwiper={(swiper) => console.log()}
                    slidesPerView="3"
                >
                    {
                        drawHistory?.map((item, index) => <SwiperSlide className={classes.LuckDrawHistoryItem} key={index+item?.email}>
                            <div>{item?.email}</div>
                            <div><FormattedMessage id="get" defaultMessage="Get" /> {item?.prize?.name}</div>
                        </SwiperSlide>)
                    }

                </Swiper>
            </div>
        }
    </>

}

const MyPrize = props => {
    const [myPrizes,setMyPrizes] = useState(null);
    const [prizesRules,setPrizesRules] = useState(null);
    const global = useSelector(state => state.global);

    const getMyPrizes = async () =>{
        let response = await fetchGetMyPrizes();
        if(response?.code === 200){
            setMyPrizes(response?.result);
        }
    }

    const getDataValue = value =>{
        let date = new Date(value).toString().split(" GMT")[0];
        let dateList = date.split(" ");
        return dateList[1]+"."+dateList[2]+"."+dateList[3]+ " "+dateList[4];
    }

    const getPrizesIcon = type =>{
        let icon;
        switch(type){
            case "1":
                icon = <Iconfont className={classes.Icon}>&#xe7e1;</Iconfont>;
                break;
            case "2":
            case "5":
                icon = <Iconfont className={classes.Icon}>&#xe7e0;</Iconfont>;
                break;
            case "3":
                icon = <Iconfont className={classes.Icon} style={{fontSize:12}}>&#xe6f8;</Iconfont>;
                break;
            case "4":
                icon = <Iconfont className={classes.Icon}>&#xe7e3;</Iconfont>;
                break;
            case "6":
                icon = <Iconfont className={classes.Icon} style={{fontSize:12}}>&#xe6f8;</Iconfont>;
                break;
            default:
                icon = <Iconfont className={classes.Icon}>&#xe7e1;</Iconfont>;
        }

        return icon;
    }

    useEffect(async () =>{
        let response = await fetchGetMessage('M1636');
        if(response?.result?.message){
            let content = response.result.message;
            setPrizesRules(content);
        }

        if(!(!global?.user || !global?.user?.id)){
            getMyPrizes();
        }
    },[]);

    useEffect(() => {
        if (global?.user || global?.user?.id) {
            getMyPrizes();
        }
    }, [global?.user?.id]);

    return <div className={classes.LotteryMyPrizes}>
        <div className={classes.MyPrizesListContainer}>
        {
            myPrizes?.length > 0 && <ul className={classes.MyPrizesList}>
                {
                    myPrizes.map((item, index) =>{
                        return <li key={index}>
                            {
                                item?.prize?.type && <>
                                    <div>
                                        {getPrizesIcon(item?.prize?.type)}
                                        
                                        {
                                            item?.prize?.type == 6 ? 
                                                (<a className={classes.Font} style={{color: "#e64444"}} href="/cart">{item?.prize?.name}</a>) : 
                                                (<span className={classes.Font}>{item?.prize?.name}</span>)
                                        }
                                        
                                    </div>

                                    <div className={classes.luckDrawDate}>{getDataValue(item?.luckDrawDate)}</div>
                                </>
                            }
                        </li>
                    })
                }
                
            </ul>
        }
        
        {
            prizesRules ? 
                <div className={classes.PrizesRules} dangerouslySetInnerHTML={{__html:prizesRules}}></div>
                :
                <div className={classes.Loading}><FormattedMessage id="loading" defaultMessage="loading" />...</div>
        }
        </div>
    </div>
}


export default injectIntl(props => {

    const {onClose, showResult} = props
    useStyles(classes)

    const [prizes, setPrizes] = useState(null)
    const [showResultMask, setShowResultMask] = useState(false)
    const [resultData, setResultData] = useState(null)
    const [drawHistory, setDrawHistory] = useState(null)
    const [showGiftModal, setShowGiftModal] = useState('')

    const global = useSelector(state => state.global)
    const isLogin = global?.user?.id
    const dispatch = useDispatch()

    useEffect(async ()=>{
        // 请求奖品列表
        const message = await fetchGetLotteryPrizes().then(data => data.result).catch(e => {
            console.error(e)
            return null
        })
        setPrizes(message)

        getDrawHistory()
    },[])

     // 请求最近抽奖历史记录(所有用户)
     const getDrawHistory = async () => {
        let response = await fetchLuckLastesthistory();
        if (response?.code === 200) {
            setDrawHistory(response?.result);
        }
    }


    const JGG_end = (res) => {
        // console.log(res)
        setShowResultMask(true)
        setResultData(res)
    }

    const getGiftData = async (id) => {
        await dispatch(action_fetch_gifts_info_message())
        await dispatch(action_add_modal_gift_list(id,{skip:0}));
    }

    const loginUrl = window.location.href?.indexOf('show_lottery') != -1 ?
                     encodeURIComponent(window.location.href):
                     window.location.href?.indexOf('?') != -1 ?
                     encodeURIComponent(window.location.href + '&show_lottery=1'): 
                     encodeURIComponent(window.location.href + '?show_lottery=1')

    return <div className={`${classes.JGG_body} ${showGiftModal? classes.JGG_height: ''}`} id="lotteryBox">
        {
            props?.onClose && !showGiftModal &&
            <img onClick={() => props?.onClose()} className={classes.JGG_Close} src={`${IMAGE_GEEKO_LTD}/chicme/2021_07_23/images/2021-07-27-lucky-circle-delete.png`} alt="" />
        }

        {
            !showGiftModal &&
            <div className={classes.JGG_container}>
                {/* 九宫格区域 */}
                <JGG_box prizes={prizes} loginUrl={loginUrl} onResult={JGG_end} isLogin={isLogin} setShowGiftModal={e => setShowGiftModal(e)} showGiftModal={showGiftModal} getGiftData={(id) => getGiftData(id)}/>

                {/* 规则和中间记录 */}
                <JGG_rule
                    loginUrl={loginUrl}
                    drawHistory={drawHistory}
                    getDrawHistory={getDrawHistory}
                    isLogin={isLogin}
                    />
            </div>
        }

        {
            (resultData) &&
            <LotteryResult
                onClose={() => {
                    setShowResultMask(false)
                    setResultData(null)
                }}
                data={resultData}
                />
        }

        {
            showGiftModal &&
            <GiftsModal 
                luckyDrawData={showGiftModal}
                zIndex={10}
                onClose={() => {
                    setShowGiftModal(null)
                }}
                />
        }
    </div>
})