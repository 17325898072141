import React, { useEffect, useState } from 'react'
import {fetchCollections} from '../../../../../api'

export default Wrapped => {
    return props => {
        const { collectionCount } = props;
        const [collections, setCollections] = useState([])

        useEffect(async () => {
            const collections =await fetchCollections({skip: 0, limit: collectionCount || 5}).then(data => data.result).catch(data => [])
            setCollections(collections)
        }, [])

        return <Wrapped {...props} collections={collections}/>
    }
}