import Type2 from './type-2'


export const Types = {
    TYPES_2: 'video-2'
}

export default type => {
    switch(type){
        case Types.TYPES_2:
            return Type2    
        default:
            return
    }
}