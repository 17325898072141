import React from 'react'
import GroupImages from '../../components/images'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Types } from './index'
import withObserver from '../../../../../pages/moduleable/modules/hoc/withObserver'
import { getStyleObjectFromString } from '../../../../../utils/style'

const Media = props => {
    const { image } = props
    if(image.mediaType === 'video'){
        return <video src={image.src} autoPlay playsInline loop muted poster={image.poster}/>
    }
    return <LazyLoadImage alt={image?.title || image?.refId} src={image.src} />
}

export default withObserver(props => {
    const { data, sensors, innerRef } = props
    const images = data?.images
    const styledTitle = data?.styledTitle
    const styles = getStyleObjectFromString(data?.style)

    return <div className={!data?.full? 'MaxWith': '' } ref={innerRef} id={data?.id} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>
        <GroupImages special={data?.special} direction={data?.direction} sensors={{
            ...sensors,
            type: '1',
        }} images={images} title={
            styledTitle ? <span dangerouslySetInnerHTML={{ __html: styledTitle }} /> : ''
        } render={(image, index) => {
            return <Media image={image} />
        }} />
    </div>
})