import React, { useState, useCallback } from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import ReactPlayer from 'react-player'
import withObserver from '../../../../../pages/moduleable/modules/hoc/withObserver'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import withSource from '../../../../../hocs/source-scoll'
import { getStyleObjectFromString } from '../../../../../utils/style'
import { getSensorsUrl } from '../../../../../utils/sensor'
import { useApp } from '../../../../../hooks/common'

const VideoWrapper = withSource(props => {
    useStyles(classes)
    const { data, sensors, innerRef, children } = props
    const isApp = useApp()
    const url = isApp ? data?.video?.appHref : data?.video?.href
    const aspectRatio = data?.video?.width && data?.video?.height ? `${data?.video?.width} / ${data?.video?.height}` : undefined


    const clickHandle = useCallback(e => {
        e.preventDefault()
        const href = e.currentTarget?.href
        window.location.href = getSensorsUrl(href, {
            resourcepage_title: sensors?.title,
            resource_type: '1',
            resource_content: data?.refId,
            resource_position: sensors?.position,
        })
    }, [])

    return <a
        ref={innerRef}
        style={{ aspectRatio }}
        className={classes.VideoWrapper}
        data-position={sensors?.position}
        data-type="1"
        data-content={data?.refId}
        data-title={sensors?.title}
        href={url}
        onClick={clickHandle}
    >
        {children}
    </a>
})

export default withObserver(props => {

    useStyles(classes)
    const { entered, innerRef, data, sensors } = props
    
    const styles = getStyleObjectFromString(data?.style)

    const [loaded, setLoaded] = useState(false)


    return <div id={data?.id} ref={innerRef} className={classes.VideoContainer} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>
        <VideoWrapper data={data} sensors={sensors}>
            {
                entered && <ReactPlayer width="100%" height="100%" className={classes.RVideo} url={data?.video?.videoSrc} 
                loop playing muted onReady={() => setLoaded(true)} 
                config={{
                    youtube: {
                        playerVars: { showinfo: 0, controls: 0, modestbranding: 1, playsinline: 1, autohide: 1, rel: 0, iv_load_policy:3 },
                        embedOptions: { showinfo: 0, controls: 0, modestbranding: 1, playsinline: 1, autohide: 1, rel: 0, iv_load_policy:3 }
                    }
                }} 
                />
            }
            <LazyLoadImage className={loaded? classes.Entered: ''}  src={data?.video?.src} />
        </VideoWrapper>
    </div>
})