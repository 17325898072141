import React from "react";
import Clipboard from 'react-clipboard.js'

export default props =>{
    const { copyValue } = props;

    return <Clipboard 
        className={props.className} 
        onSuccess={props.successCallback} 
        data-clipboard-text={copyValue}
    >
        {props.children}
    </Clipboard>
}