import React, { useState, useCallback } from 'react'
import GroupImages from '../../components/images'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import withObserver from '../../../../../pages/moduleable/modules/hoc/withObserver'
import { getStyleObjectFromString } from '../../../../../utils/style'
import PopupVideo from './fragments/popup-video'


export default withObserver(props => {
    const { data, sensors, innerRef } = props
    const images = data?.images
    const styledTitle = data?.styledTitle
    const styles = getStyleObjectFromString(data?.style)

    const [imageData, setImageData] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const showVideoEvent = useCallback((imageData) =>{
        setImageData(imageData);
        setShowModal(true);
    }, []);

    return <div className={!data?.full? 'MaxWith': '' } ref={innerRef} id={data?.id} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>
        <GroupImages special={data?.special} direction={data?.direction} sensors={{
            ...sensors,
            type: '1',
        }} images={images} title={
            styledTitle ? <span dangerouslySetInnerHTML={{ __html: styledTitle }} /> : ''
        } render={(image, index) => {
            return <LazyLoadImage alt={image?.title} src={image.src} />
        }} 
        showVideoEvent={(data) =>showVideoEvent(data)}
        />

        <PopupVideo 
            show={showModal} 
            imageData={imageData} 
            onClose={() =>setShowModal(false)} 
        />
    </div>
})