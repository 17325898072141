import React,{useCallback} from "react";
import classes from "./style.module.scss";
import useStyles from "isomorphic-style-loader/useStyles";
import { useMemo } from "react";
import { FullSwiper } from "../../../../../pages/moduleable/components/swiper";
import { getStyleObjectFromString } from "../../../../../utils/style";
import { Iconfont } from "../../../../../components/icon/iconfont";
import { getSensorsUrl } from "../../../../../utils/sensor";
import withSource from '../../../../../hocs/source-scoll'

const TipsItem = withSource(props =>{
    const { tip, position, sensors, innerRef } = props;

    const clickHandle = useCallback(e => {
		e.preventDefault()
		const href = e.currentTarget?.href
		window.location.href = getSensorsUrl(href, {
			resourcepage_title: sensors?.title,
			resource_type: '1',
			resource_content: tip?.refId || tip?.title,
			resource_position: `${sensors?.position}-${position+1}`,
		})
	}, [])

    return tip?.href ? <a 
            className={classes.TipsItem} 
            ref={innerRef}
            href={tip?.href}
            data-position={`${sensors?.position}-${position+1}`}
            data-type="1"
            data-content={tip?.refId || tip?.title}
            data-title={sensors?.title}
            onClick={clickHandle}
        >
        {
            tip?.icon && <div className={classes.IconContainer}>
                <Iconfont style={{fontSize:tip?.iconSize}} dangerouslySetInnerHTML={{__html:tip?.icon}}></Iconfont>
            </div>
        }

        {
            tip?.styledTitle && <div className={classes.Message}>
                <span dangerouslySetInnerHTML={{__html:tip?.styledTitle}}></span>
            </div>
        }
    </a> : <div 
            className={classes.TipsItem}
            ref={innerRef}
            data-position={`${sensors?.position || 1}-${(position || 0) + 1}`}
            data-type="1"
            data-content={tip?.refId || tip?.title}
            data-title={sensors?.title}
        >
        {
            tip?.icon && <div className={classes.IconContainer}>
                <Iconfont style={{fontSize:tip?.iconSize}} dangerouslySetInnerHTML={{__html:tip?.icon}}></Iconfont>
            </div>
        }

        {
            tip?.styledTitle && <div className={classes.Message}>
                <span dangerouslySetInnerHTML={{__html:tip?.styledTitle}}></span>
            </div>
        }
    </div>
})

const TipSwiper = props =>{
    const { tips } = props;

    return <FullSwiper
        items={tips}
        className={classes.TipSwiper}
        options={
            {
                direction: 'vertical',
                loop: true,
                autoplay:{
                    delay: 5000
                }
            }
        }
        render={(tip, index) => {
            return <TipsItem key={index} {...props} tip={tip} position={index} />
        }}
    >

    </FullSwiper>
}

export default props =>{
    useStyles(classes);
    const { data, sensors } = props;
    const { tips } = data;

    const styles = getStyleObjectFromString(data?.style)
    
    const tipsArr = useMemo(()=>{
        if(tips.length > 3){
            let [data1, data2, ...data3] = tips;
            return Array.of(data1, data2, data3)
        }else{
            return tips;
        }
    },[tips]);

    return <div className={`${!data?.full? 'MaxWith': ''}`} id={data?.id} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>
        <div className={classes.TipsContainer}>
            {
                tipsArr?.map((tip,index)=>{
                    return <div className={classes.TipsCon}>
                        {
                            tip instanceof Array ? (
                                <TipSwiper key={index} tips={tip} position={index} sensors={sensors} />
                            ) : (
                                <TipsItem key={index} tip={tip} position={index} sensors={sensors} />
                            )
                        }
                    </div>
                })
            }
        </div>
    </div>
}