import React, { useEffect, useState, createRef } from 'react'
import { fetchCategoryList, fetchCollectionList } from '../../../../../../api'
import INTERFACE_TYPE from './eumn'


export default Wrapper => {

    return props => {

        const [products, setProducts] = useState([])
        const ref = createRef()
        let inited = false


        useEffect(() => {
            let ob = new IntersectionObserver(obHandle)
            ob.observe(ref.current)
            return () => {
                ob.disconnect()
            }
        }, [])


        const obHandle = entries => {
            const entry = entries?.[0]
            if (entry?.intersectionRatio > 0) {
                initProducts()
            }
        }


        const initProducts = async () => {
            if (!inited) {
                inited = true
                const { data } = props
                const { relatedId, productCount } = data
                let result
                switch (data?.interface) {
                    case INTERFACE_TYPE.COLLECTION:
                        result = await fetchCollectionList({ collectionId: relatedId }, { skip: 0, limit: productCount || 12 }).then(data => data.result).catch(e => {
                            console.error(e)
                            return []
                        })
                        break
                    case INTERFACE_TYPE.CATEGORY:
                        result = await fetchCategoryList({ categoryId: relatedId }, { skip: 0, limit: productCount || 12 }).then(data => data.result).catch(e => {
                            console.error(e)
                            return []
                        })
                        break
                    default:
                        result = []
                        break
                }
                setProducts(result?.filter(p=> !p.isCollection && !p.isSmartCard ))
            }


        }


        return <div ref={ref}>
            <Wrapper {...props} products={products} />
        </div>
    }
}