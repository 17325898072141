import React, { useState, useEffect, createRef } from 'react'
import { fetchCategoryList, fetchCollectionList } from '../../../../../../api'
import INTERFACE_TYPE from './eumn'


export default Wrapper => {

    return props => {

        const [groups, setGroups] = useState(props.data?.collections)
        const ref = createRef()
        let inited = false

        useEffect(() => {
            let ob = new IntersectionObserver(obHandle)
            ob.observe(ref.current)
            return () => {
                ob.disconnect()
            }
        }, [])


        const obHandle = entries => {
            const entry = entries?.[0]
            if (entry?.intersectionRatio > 0) {
                initData()
            }
        }

        const initData = async () => {

            if (!inited) {
                inited = true
                const { data } = props
                const { collections, productCount } = data
                const promises = collections?.map(collection => {
                    switch (collection.interface) {
                        case INTERFACE_TYPE.CATEGORY:
                            return fetchCategoryList({ categoryId: collection.id }, { skip: 0, limit: productCount || 14 }).then(data => ({ ...collection, products: [...data.result?.filter(p=> !p.isCollection && !p.isSmartCard ), {...collection, id:null}] })).catch(e => {
                                console.error(e)
                                return collection
                            })
                        default:
                            return fetchCollectionList({ collectionId: collection.id }, { skip: 0, limit: productCount || 14 }).then(data => ({ ...collection, products: [...data.result?.filter(p=> !p.isCollection && !p.isSmartCard ), {...collection, id:null}] })).catch(e => {
                                console.error(e)
                                return collection
                            })
                    }
                })
                const groups = await Promise.all(promises)
                setGroups(groups)
            }

        }

        return <div ref={ref}>
            <Wrapper {...props} collections={groups} />
        </div>
    }
}